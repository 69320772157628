import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { moveItemInArray } from '@angular/cdk/drag-drop';
// Components
import { DialogContributorsComponent } from 'src/app/components/dialog-contributors/dialog-contributors.component';
import { DialogPublishersComponent } from 'src/app/components/dialog-publishers/dialog-publishers.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogNewContributorComponent } from '../add-edit-contributor/add-edit-contributor.component';
import { DialogAssignBibliographyComponent } from '../dialog-assign-bibliography/dialog-assign-bibliography.component';
// Classes
import { Book, BookGenre, CCBC_COLLECTION_OPTIONS } from 'src/app/classes/Book';
import { Contributor } from 'src/app/classes/Contributor';
import { Publisher, BookPublishers } from 'src/app/classes/Publisher';
import { BooksCategories } from 'src/app/classes/BooksCategories';
import { Recommendation } from 'src/app/classes/Recommendation';
import { Character } from 'src/app/classes/character';
import { throwError } from 'rxjs';
/**
 * Allows editing of all information about a single book.
 */
var EditBookComponent = /** @class */ (function () {
    function EditBookComponent(dialog, route, router, bookService, contributorService, bibliographyService, titleService) {
        this.dialog = dialog;
        this.route = route;
        this.router = router;
        this.bookService = bookService;
        this.contributorService = contributorService;
        this.bibliographyService = bibliographyService;
        this.titleService = titleService;
        // Local variables
        this.hasGetBookError = false;
        // We'll split book.characters using the characters.isSecondaryCharacter property
        // so the template can loop over them seperately
        this.bookPrimaryCharacters = new Array();
        this.bookSecondaryCharacters = new Array();
        this.isNewBook = false;
        this.richTextHasError = false;
        this.loading = true;
        this.saveSuccessful = false;
        this.saveError = false;
        this.isLoadingContributors = true;
        this.visible = true;
        this.selectable = true;
        this.removable = true;
        this.addOnBlur = true;
        this.separatorKeysCodes = [ENTER, COMMA];
        // Publishers section
        this.allPublishers = new Array();
        this.publisherOptions = new Array();
        this.selectedPublishers = new Array();
        this.publishersToSave = new Array();
        this.newPublisher = new Publisher();
        // Collection section
        this.ccbcCollectionOptions = CCBC_COLLECTION_OPTIONS;
        this.allGenres = new Array();
        this.genreOptions = new Array();
        this.selectedGenres = new Array();
        this.genresToSave = new Array();
        //bibliography
        this.bookBibliographies = new Array();
        this.bookBibliographiesToBeDeleted = new Array();
        //stuff to get tinyMce to work with tabs
        this.activeTabIndex = 0;
    }
    EditBookComponent.prototype.handleTabChange = function (e) {
        this.activeTabIndex = e.index;
    };
    EditBookComponent.prototype.ngOnInit = function () {
        this.contributorsMarkedForDeletion = new Array();
        this.contributorTypes = new Array();
        this.allContributors = new Array();
        this.getBook();
        this.getAllContributors();
        this.getContributorTypes();
        this.getAllGenres();
        this.isPrimary = "Pri"; //to avoid duplicate name 
        this.isSecondary = "Sec"; //    
    };
    EditBookComponent.prototype.setTitle = function (title) {
        this.titleService.setTitle(title);
    };
    /**
     * Gets the requested book and binds to the view.
     */
    EditBookComponent.prototype.getBook = function () {
        var _this = this;
        // Get book ID from route and cast as number
        var id = +this.route.snapshot.paramMap.get('id');
        if (id === 0) {
            this.book = new Book();
            this.extractPrimaryAndSecondaryCharactersForDisplay(); // split the characters array into primary & secondary arrays for display purposes
            this.isNewBook = true;
            this.loading = false;
            this.setTitle('New book | CCBC Book Search');
            this.transformPublishers([]);
        }
        else {
            this.setTitle('Edit book | CCBC Book Search');
            // Get the requested book details
            this.bookService.getBookById(id).subscribe(function (book) {
                _this.book = book;
                _this.extractPrimaryAndSecondaryCharactersForDisplay(); // split the characters array into primary & secondary arrays for display purposes
                _this.loading = false;
                _this.publishersToSave = book.bookPublishers;
                _this.transformPublishers(book.bookPublishers);
                _this.genresToSave = book.bookGenres;
                _this.transformGenres(book.bookGenres);
                if (_this.book.recommendation === null) {
                    _this.book.recommendation = new Recommendation();
                    _this.book.recommendation.bookId = _this.book.id;
                }
            }, function (err) {
                _this.loading = false;
                _this.hasGetBookError = true;
                _this.errorId = _this.route.snapshot.paramMap.get('id');
                _this.book = new Book();
            });
            //if loading book completes without error, load bookbibliography if any
            if (!this.hasGetBookError && this.loading && !this.isNewBook) {
                this.bibliographyService.getBookBibliographyById(id).subscribe(function (bioblio) {
                    _this.bookBibliographies = bioblio;
                });
            }
        }
    };
    // Split the single array of characters from the book model
    // into seperate arrays for primary and secondary characters.
    // Also, add empty placeholder primary & secondary characters for display purposes
    EditBookComponent.prototype.extractPrimaryAndSecondaryCharactersForDisplay = function () {
        this.bookPrimaryCharacters = new Array();
        this.bookSecondaryCharacters = new Array();
        // Primary Characters
        var tempPrimaryCharacters = this.book.characters.filter(function (character) { return character.isSecondaryCharacter === false; });
        // How many primary characters should we display for this book?
        // We'll always show 5 primary characters, but can show more if the book has more
        var numPrimaryCharactersToShow = tempPrimaryCharacters.length > 5 ? tempPrimaryCharacters.length : 5;
        var _loop_1 = function (i) {
            // If we don't have 5 characters, and we don't have a character with a sort order at or below this position (i)
            if (tempPrimaryCharacters.length < 5 && tempPrimaryCharacters.filter(function (c) { return c.sortOrder <= i + 1; }).length == 0) {
                // Inject a placeholder character for this position
                var placeholderCharacter = new Character();
                placeholderCharacter.isSecondaryCharacter = false;
                placeholderCharacter.bookId = this_1.book.id;
                this_1.bookPrimaryCharacters.push(placeholderCharacter);
            }
            else {
                // Else shift the next character into our list to display
                this_1.bookPrimaryCharacters.push(tempPrimaryCharacters.shift());
            }
        };
        var this_1 = this;
        for (var i = 0; i < numPrimaryCharactersToShow; i++) {
            _loop_1(i);
        }
        // Secondary Characters
        var tempSecondaryCharacters = this.book.characters.filter(function (character) { return character.isSecondaryCharacter === true; });
        // How many secondary characters should we display for this book?
        // We'll always show 5 secondary characters, but can show more if the book has more
        var numSecondaryCharactersToShow = tempSecondaryCharacters.length > 5 ? tempSecondaryCharacters.length : 5;
        var _loop_2 = function (i) {
            // If we don't have 5 characters, and we don't have a character with a sort order at or below this position (i)
            if (tempSecondaryCharacters.length < 5 && tempSecondaryCharacters.filter(function (c) { return c.sortOrder <= i + 1; }).length == 0) {
                // Inject a placeholder character for this position
                var placeholderCharacter = new Character();
                placeholderCharacter.isSecondaryCharacter = true;
                placeholderCharacter.bookId = this_2.book.id;
                this_2.bookSecondaryCharacters.push(placeholderCharacter);
            }
            else {
                // Else shift the next character into our list to display
                this_2.bookSecondaryCharacters.push(tempSecondaryCharacters.shift());
            }
        };
        var this_2 = this;
        for (var i = 0; i < numSecondaryCharactersToShow; i++) {
            _loop_2(i);
        }
    };
    // Merge and transform the separate primary & secondary character arrays used by the template
    // into a single characters array for saving
    EditBookComponent.prototype.mergePrimaryAndSecondaryCharactersForSave = function () {
        var mergedCharacters = new Array();
        for (var i = 0; i < this.bookPrimaryCharacters.length; i++) {
            // If any fields filled in
            if (!this.isCharacterEmpty(this.bookPrimaryCharacters[i])) {
                // Apply an incrementing sort order
                this.bookPrimaryCharacters[i].sortOrder = i + 1;
                // Add character to the array for saving
                mergedCharacters.push(this.bookPrimaryCharacters[i]);
            }
        }
        for (var i = 0; i < this.bookSecondaryCharacters.length; i++) {
            // If any fields filled in
            if (!this.isCharacterEmpty(this.bookSecondaryCharacters[i])) {
                // Apply an incrementing sort order
                this.bookSecondaryCharacters[i].sortOrder = i + 1;
                // Add character to the array for saving
                mergedCharacters.push(this.bookSecondaryCharacters[i]);
            }
        }
        return mergedCharacters;
    };
    // Check if any fields have been filled for the given character
    EditBookComponent.prototype.isCharacterEmpty = function (character) {
        if ((character.disabilityNotes !== undefined && character.disabilityNotes !== '' && character.disabilityNotes !== null)
            || (character.heritageByNotes !== undefined && character.heritageByNotes !== '' && character.heritageByNotes !== null)
            || (character.religionNotes !== undefined && character.religionNotes !== '' && character.religionNotes !== null)
            || (character.sexualityNotes !== undefined && character.sexualityNotes !== '' && character.sexualityNotes !== null)
            || (character.typeNotes !== undefined && character.typeNotes !== '' && character.typeNotes !== null)
            || (character.heritageByNotes !== undefined && character.heritageByNotes !== '' && character.heritageByNotes !== null)
            || (character.genderNotes !== undefined && character.genderNotes !== '' && character.genderNotes !== null)
            || character.charactersCharacterGenders.length !== 0
            || character.charactersCharacterGenderSpecifies.length !== 0
            || character.charactersCharacterHeritages.length !== 0
            || character.charactersCharacterHeritageSpecifies.length !== 0
            || character.charactersCharacterSexualities.length !== 0
            || character.charactersCharacterTypes.length !== 0
            || character.charactersDisabilities.length !== 0
            || character.charactersReligions.length !== 0) {
            return false;
        }
        return true;
    };
    /**
     * Transform exististing publishers into data consumable by the multi-select
     * @param {Array<BookPublishers>} publishers Publishers already associated with this book
     */
    EditBookComponent.prototype.transformPublishers = function (publishers) {
        var _this = this;
        publishers.forEach(function (item) {
            var data = {
                option: item.publisherId,
                displayText: item.publisher.name
            };
            _this.selectedPublishers.push(data);
        });
        this.getAllPublishers();
    };
    /**
   * Transform exististing genres into data consumable by the multi-select
   * @param {Array<BookGenre>} genres Genres already associated with this book
   */
    EditBookComponent.prototype.transformGenres = function (genres) {
        var _this = this;
        genres.forEach(function (item) {
            var data = {
                option: item.genreId,
                displayText: item.genre.displayName
            };
            _this.selectedGenres.push(data);
        });
    };
    EditBookComponent.prototype.getAllContributors = function () {
        var _this = this;
        this.contributorService.getContributors().subscribe(function (contributors) {
            _this.allContributors = contributors;
            _this.isLoadingContributors = false;
        });
    };
    /**
     * Gets available types of contributors, binds to the view in order for new contributors to be added to the book being edited.
     */
    EditBookComponent.prototype.getContributorTypes = function () {
        var _this = this;
        this.contributorService.getContributorTypes().subscribe(function (contributorTypes) {
            var e_1, _a;
            try {
                for (var contributorTypes_1 = tslib_1.__values(contributorTypes), contributorTypes_1_1 = contributorTypes_1.next(); !contributorTypes_1_1.done; contributorTypes_1_1 = contributorTypes_1.next()) {
                    var type = contributorTypes_1_1.value;
                    _this.contributorTypes.push(type.displayName);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (contributorTypes_1_1 && !contributorTypes_1_1.done && (_a = contributorTypes_1.return)) _a.call(contributorTypes_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        });
    };
    /**
     * Get all known publishers
     */
    EditBookComponent.prototype.getAllPublishers = function () {
        var _this = this;
        this.bookService.getPublishers().subscribe(function (publishers) {
            if (publishers != undefined && publishers.length > 0) {
                _this.allPublishers = publishers;
                _this.assemblePublisherOptions(publishers);
            }
        });
    };
    /**
     * Convert publisher objects on the book into objects consumable by the multi-select
     * @param publishers
     */
    EditBookComponent.prototype.assemblePublisherOptions = function (publishers) {
        var e_2, _a;
        try {
            for (var publishers_1 = tslib_1.__values(publishers), publishers_1_1 = publishers_1.next(); !publishers_1_1.done; publishers_1_1 = publishers_1.next()) {
                var publisher = publishers_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: publisher.publisherId,
                    displayText: publisher.name
                };
                this.publisherOptions.push(data);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (publishers_1_1 && !publishers_1_1.done && (_a = publishers_1.return)) _a.call(publishers_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        this.publisherOptions = this.publisherOptions.slice();
    };
    /**
     * Get everything currently in the multi-select, transform to match data model expected by
     * API, then update the list of publishers that should be saved to the book.
     */
    EditBookComponent.prototype.updatePublishersToSave = function () {
        var _this = this;
        // Transform multi-select data into book publishers
        var updatedPublishers = new Array();
        this.selectedPublishers.forEach(function (item) {
            var bookPublisher = {
                bookId: _this.book.id,
                publisherId: item.option,
                publisher: _this.allPublishers.find(function (entry) { return entry.publisherId === item.option; })
            };
            updatedPublishers.push(bookPublisher);
        });
        this.publishersToSave = updatedPublishers;
    };
    /**
   * Get all genres
   */
    EditBookComponent.prototype.getAllGenres = function () {
        var _this = this;
        this.bookService.getGenres().subscribe(function (genres) {
            if (genres != undefined && genres.length > 0) {
                _this.allGenres = genres;
                _this.assembleGenreOptions(genres);
            }
        });
    };
    /** Convert genre objects on the book into objects consumable by the multi-select
     * @param genres
     */
    EditBookComponent.prototype.assembleGenreOptions = function (genres) {
        var e_3, _a;
        try {
            for (var genres_1 = tslib_1.__values(genres), genres_1_1 = genres_1.next(); !genres_1_1.done; genres_1_1 = genres_1.next()) {
                var genre = genres_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: genre.id,
                    displayText: genre.displayName
                };
                this.genreOptions.push(data);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (genres_1_1 && !genres_1_1.done && (_a = genres_1.return)) _a.call(genres_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        this.genreOptions = this.genreOptions.slice();
    };
    /**
     * Get everything currently in the multi-select, transform to match data model expected by
     * API, then update the list of publishers that should be saved to the book.
     */
    EditBookComponent.prototype.updateGenresToSave = function () {
        var _this = this;
        // Transform multi-select data into book publishers
        var updatedGenres = new Array();
        this.selectedGenres.forEach(function (item) {
            var bookGenre = {
                bookId: _this.book.id,
                genreId: item.option,
                genre: _this.allGenres.find(function (entry) { return entry.id === item.option; })
            };
            updatedGenres.push(bookGenre);
        });
        this.genresToSave = updatedGenres;
    };
    /////////////////////
    // EVENT LISTENERS //
    /////////////////////
    /**
     * Updates all changed fields on the current book, updates the UI where necessary, then sends the
     * updated book off to be saved.
     */
    EditBookComponent.prototype.onSave = function () {
        var _this = this;
        var newCoverImage = this.book.recommendation.newBookCover;
        // Copy the current book before updating its information
        var bookToSave = JSON.parse(JSON.stringify(this.book));
        this.saveSuccessful = false;
        this.saveError = false;
        // Remove deleted contributors
        this.contributorsMarkedForDeletion.forEach(function (contrib) {
            var index = _this.book.bookContributors.indexOf(contrib);
            _this.book.bookContributors.splice(index, 1);
            if (contrib.bookId != 0) {
                _this.bookService.removeContributor(contrib).subscribe(function (result) {
                    // Confirm removal or handle errors
                });
            }
        });
        // Save remaining contributors
        var contributorOrder = 1;
        bookToSave.bookContributors = new Array();
        this.book.bookContributors.forEach(function (contrib) {
            var newContrib = new Contributor();
            newContrib.bookId = bookToSave.id;
            newContrib.contributorId = contrib.contributorId;
            newContrib.sortOrder = contributorOrder;
            contributorOrder++;
            newContrib.contributorTypeId = contrib.contributorTypeId;
            bookToSave.bookContributors.push(newContrib);
        });
        // Save any changes to publishers
        bookToSave.bookPublishers = this.publishersToSave.map(function (entry) {
            var pub = new BookPublishers();
            pub.publisherId = entry.publisherId;
            pub.bookId = bookToSave.id;
            return pub;
        });
        // save any changes to genres
        bookToSave.bookGenres = this.genresToSave.map(function (entry) {
            var genre = new BookGenre();
            genre.genreId = entry.genreId;
            genre.bookId = bookToSave.id;
            return genre;
        });
        //remove bookbiliography
        if (this.bookBibliographiesToBeDeleted.length > 0) {
            this.bookBibliographiesToBeDeleted.forEach(function (bookbibliography) {
                var booksCategory = new BooksCategories();
                booksCategory.bookId = bookbibliography.bookId;
                booksCategory.bibliographyCategoryId = bookbibliography.categoryId;
                var index = _this.book.booksCategories.findIndex(function (item) { return item.bibliographyCategoryId === bookbibliography.categoryId; });
                var indexBookBilio = _this.bookBibliographies.indexOf(bookbibliography);
                if (index > -1) {
                    _this.book.booksCategories.splice(index, 1);
                }
                //update current bookbiliography model to refresh view
                if (indexBookBilio > -1) {
                    _this.bookBibliographies.splice(indexBookBilio, 1);
                }
            });
        }
        //add new bibliography to save list 
        if (this.bookBibliographies.length > 0) {
            this.bookBibliographies.forEach(function (bookbibliography) {
                var booksCategory = new BooksCategories();
                booksCategory.bookId = bookbibliography.bookId;
                booksCategory.bibliographyCategoryId = bookbibliography.categoryId;
                var index = _this.book.booksCategories.findIndex(function (item) { return item.bibliographyCategoryId === bookbibliography.categoryId; });
                //this is a new bibliography assigned to this book
                if (index < 0) {
                    _this.book.booksCategories.push(booksCategory);
                }
            });
        }
        // Save remaining book cateogries
        bookToSave.booksCategories = this.book.booksCategories.map(function (booksCategory) {
            var newBookCategory = new BooksCategories();
            newBookCategory.bookId = booksCategory.bookId;
            newBookCategory.bibliographyCategoryId = booksCategory.bibliographyCategoryId;
            return newBookCategory;
        });
        // Extract primary & secondary characters with populated fields, and merge into characters[] for saving
        bookToSave.characters = this.mergePrimaryAndSecondaryCharactersForSave();
        this.bookService.saveBook(bookToSave).subscribe({
            next: function (book) {
                _this.saveSuccessful = true;
                _this.lastSavedDate = new Date();
                _this.contributorsMarkedForDeletion = new Array();
                console.info('save successful: ', book);
                // Reload route with newly set book id
                if (_this.isNewBook) {
                    if (newCoverImage) { //update new image, then reload route
                        _this.bookService.postFile(newCoverImage, book.id).subscribe(function (result) {
                            _this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; }; //https://stackoverflow.com/questions/52389376/angular-6-how-to-reload-current-page
                            _this.router.onSameUrlNavigation = 'reload';
                            _this.router.navigate(['/book/' + book.id]);
                        });
                    }
                    else { //just reload the route
                        _this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; }; //https://stackoverflow.com/questions/52389376/angular-6-how-to-reload-current-page
                        _this.router.onSameUrlNavigation = 'reload';
                        _this.router.navigate(['/book/' + book.id]);
                    }
                }
                else if (newCoverImage) { //if existing book just upload the new image
                    _this.bookService.postFile(newCoverImage, book.id).subscribe(function (result) { });
                }
                // Need to refresh the characters bound to the template, 
                // so the ids of deleted characters are cleared and ids of newly created characters are set
                _this.book.characters = book.characters;
                _this.extractPrimaryAndSecondaryCharactersForDisplay();
            },
            error: function (err) {
                window.alert('The application encountered an error saving the book.');
                throwError(err);
            }
        });
    };
    /**
     * Displays a modal where users can create new contributors or add existing ones to the book being edited.
     */
    EditBookComponent.prototype.showContributorsModal = function () {
        var _this = this;
        // Create new dialog with data that matches ContributorDataInterface
        var dialogRef = this.dialog.open(DialogContributorsComponent, {
            width: '80%',
            data: {
                bookTitle: this.book.title,
                bookId: this.book.id,
                contributorsList: this.allContributors,
                existingContributors: this.book.bookContributors
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            // Add new contributors to the book
            if (result.length > 0) {
                result.forEach(function (contributor) {
                    _this.book.bookContributors.push(contributor);
                });
            }
            _this.getAllContributors();
        });
    };
    /**
     * Displays a modal for editing data about a contributor that exists on this book record
     * @param id The id of the contributor to be edited
     */
    EditBookComponent.prototype.showEditContributorModal = function (id) {
        var _this = this;
        var dialogRef = this.dialog.open(DialogNewContributorComponent, {
            width: '80%',
            data: {
                contributor: this.allContributors.find(function (entry) { return entry.id === id; }),
                isNewContributor: false
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result == -1) {
                return;
            }
            _this.contributorService.saveContributor(result)
                .subscribe(function (contributor) {
                console.info('updated contributor', contributor);
            });
        });
    };
    /**
     * Mark a contributor for deletion or remove a contributor from the list of marked ones.
     * @param {$event} event Checkbox event when the remove box is checked or unchecked
     * @param {Contributor} contributor Contributor to mark or unmark
     * @param {number} index Index of the contributor in the list
     */
    EditBookComponent.prototype.toggleDeleteContributor = function (event, contributor, index) {
        if (this.book.bookContributors.indexOf(contributor) > -1) {
            if (event.checked) {
                this.contributorsMarkedForDeletion.push(contributor);
            }
            else {
                // Remove id from deletion list if it's there
                var index_1 = this.contributorsMarkedForDeletion.indexOf(contributor);
                if (index_1 > -1) {
                    this.contributorsMarkedForDeletion.splice(index_1, 1);
                }
            }
        }
    };
    EditBookComponent.prototype.onContributorDrop = function (event) {
        moveItemInArray(this.book.bookContributors, event.previousIndex, event.currentIndex);
    };
    /**
     * Displays a modal where users can create new publishers to add to the book being edited
     */
    EditBookComponent.prototype.showPublishersModal = function () {
        var _this = this;
        // Create new dialog with data that matches IPublisherData
        var dialogRef = this.dialog.open(DialogPublishersComponent, {
            width: '80%',
            data: {
                allPublishers: this.allPublishers
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            // Check for cancellation
            if (result === -1 || result === undefined) {
                return;
            }
            // Save new publisher via service
            _this.bookService.addPublisher(result)
                .subscribe(function (publisher) {
                // Transform and add selection, then copy selected publishers array to new reference
                // to be displayed in the multi-select
                _this.selectedPublishers.push({
                    option: publisher.publisherId,
                    displayText: publisher.name
                });
                _this.selectedPublishers = _this.selectedPublishers.slice();
                _this.updatePublishersToSave();
                //add new publisher to the list of all publishers
                _this.allPublishers.push(publisher);
            });
        });
    };
    /**
     * Transform multi-select selections into BookPublishers and pass them on for later saving
     * @param {Array<IMultiSelectOptions>} publishers List of publishers currently selected in the multi-select
     */
    EditBookComponent.prototype.setSelectedPublishers = function (publishers) {
        this.selectedPublishers = publishers;
        this.selectedPublishers = this.selectedPublishers.slice();
        this.updatePublishersToSave();
    };
    /**
     * Transform multi-select selections into BookGenres and pass them on for later saving
     * @param {Array<IMultiSelectOptions>} genres List of publishers currently selected in the multi-select
     */
    EditBookComponent.prototype.setSelectedGenres = function (genres) {
        this.selectedGenres = genres;
        this.selectedGenres = this.selectedGenres.slice();
        this.updateGenresToSave();
    };
    EditBookComponent.prototype.checkMCEInput = function (event) {
        this.richTextHasError = event;
    };
    /*Bibliography checkbox Remove event  */
    EditBookComponent.prototype.deleteBibliographyFromBook = function (event, bookbibliography, index) {
        if (this.bookBibliographies.indexOf(bookbibliography) > -1) {
            if (event.checked) {
                //push to delete array 
                this.bookBibliographiesToBeDeleted.push(bookbibliography);
            }
            else {
                // Remove id from deletion array
                var index_2 = this.bookBibliographiesToBeDeleted.indexOf(bookbibliography);
                if (index_2 > -1) {
                    this.bookBibliographiesToBeDeleted.splice(index_2, 1);
                }
            }
        }
    };
    EditBookComponent.prototype.assignBibliography = function (bookId) {
        // Create new dialog of bibliography
        var dialogBiblioRef = this.dialog.open(DialogAssignBibliographyComponent, {
            width: '50%',
            height: '60%',
            data: {
                bookId: this.book.id,
                bookBibliographies: this.bookBibliographies
            }
        });
        dialogBiblioRef.afterClosed().subscribe(function (result) {
        });
    };
    return EditBookComponent;
}());
export { EditBookComponent };
