import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
// Classes
import { Contributor, ContributorPersonalInfo } from 'src/app/classes/Contributor';
// Components
import { DialogNewContributorComponent } from 'src/app/components/add-edit-contributor/add-edit-contributor.component';
/**
 * Dialog that appears when a user clicks the "Add contributor(s)" button when editing a book.
 */
var DialogContributorsComponent = /** @class */ (function () {
    function DialogContributorsComponent(dialogRef, contributorService, dialog, removeDupes, data) {
        var _this = this;
        this.dialogRef = dialogRef;
        this.contributorService = contributorService;
        this.dialog = dialog;
        this.removeDupes = removeDupes;
        this.data = data;
        this.contributorsSelect = new FormControl();
        this.rolesSelect = new FormControl();
        this.existingContributors = new Array();
        this.addedSuccess = false;
        this.optionsSubject = new Subject();
        this.isSelectHidden = true;
        this.isUpdating = false;
        this.dialogRef.beforeClose().subscribe(function () { return _this.dialogRef.close(_this.contributorsToAdd); });
    }
    DialogContributorsComponent.prototype.ngOnInit = function () {
        var _this = this;
        // Initialize list of contributors to return
        this.contributorsToAdd = new Array();
        this.selectedContributors = new Array();
        // Capture any existing contributors
        this.contributorService.getContributorTypes().subscribe(function (types) {
            _this.rolesList = types;
        });
        if (this.data.existingContributors) {
            this.existingContributors = this.data.existingContributors;
        }
        if (this.data.contributorsList) {
            this.allContributors = this.data.contributorsList;
        }
        // Register change listener on role select to update options list
        this.rolesSelect.valueChanges.subscribe(function () {
            _this.isSelectHidden = false;
            _this.assembleMultiSelectOptions();
        });
    };
    /**
     * Transforms contributors to match interface expected by multi-select component, updates service that the multi-select is listening to.
     * @param {Array<ContributorPersonalInfo>} contributors Contributor objects to transform to match multi-select options interface
     */
    DialogContributorsComponent.prototype.assembleMultiSelectOptions = function () {
        var e_1, _a;
        // Update list of all contributors and filterables, reinitialize multi-select options
        this.multiSelectOptions = new Array();
        var roleId = this.rolesSelect.value.id;
        var filteredContributors = this.allContributors.slice();
        // Check if this selected role matches any contributor type IDs in the existing contributors array
        // If a role matches, check if the entry's ID exists in the contributors list
        // If so, remove the matching element from the list before creating the multi-select options    
        this.existingContributors.forEach(function (contrib) {
            if (contrib.contributorTypeId === roleId) {
                var removeMe = filteredContributors.find(function (entry) {
                    if (entry.id === contrib.contributorId) {
                        return entry;
                    }
                });
                filteredContributors.splice(filteredContributors.indexOf(removeMe), 1);
            }
        });
        try {
            // Convert data to match IMultiSelectOptions
            for (var filteredContributors_1 = tslib_1.__values(filteredContributors), filteredContributors_1_1 = filteredContributors_1.next(); !filteredContributors_1_1.done; filteredContributors_1_1 = filteredContributors_1.next()) {
                var contributor = filteredContributors_1_1.value;
                var data = {
                    option: contributor.id,
                    displayText: contributor.lastName + ', ' + contributor.firstName + ' ' + contributor.middleName
                };
                this.multiSelectOptions.push(data);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (filteredContributors_1_1 && !filteredContributors_1_1.done && (_a = filteredContributors_1.return)) _a.call(filteredContributors_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        // Update multi-select options list
        this.optionsSubject.next({ list: this.multiSelectOptions, resetSelections: true });
    };
    /**
     * Receive selections from the multi-select component, set the view's array equal to them,
     * then reassign the array reference so changes are detected throughout the components.
     * @param {Array<IMultiSelectOptions>} selections User's multi-select selections
     */
    DialogContributorsComponent.prototype.updateSelections = function (selections) {
        this.selectedContributors = selections;
        this.selectedContributors = this.selectedContributors.slice();
    };
    /**
     * Get user's selections from multi-select component and transform into data format expected
     * by edit-book component (Contributor/BookContributor).
     */
    DialogContributorsComponent.prototype.getSelections = function () {
        var e_2, _a;
        var selections = this.selectedContributors;
        if (selections != undefined && selections.length > 0) {
            var _loop_1 = function (contributor) {
                var newEntry = new Contributor();
                newEntry.bookId = 0; // Purposely inititalizing as zero instead of this.data.bookId, so we can identify the contributors that are new since the last save
                if (this_1.lastNewContributor && contributor.option == this_1.lastNewContributor.id) {
                    newEntry.contributor = this_1.lastNewContributor;
                }
                else {
                    newEntry.contributor = this_1.allContributors.find(function (entry) {
                        if (entry.id === contributor.option) {
                            // Recast return value to avoid typescript compilation errors ("find" method returns boolean by default)
                            return entry;
                        }
                    });
                }
                newEntry.contributorId = contributor.option;
                newEntry.contributorType = this_1.rolesSelect.value;
                newEntry.contributorTypeId = this_1.rolesSelect.value['id'];
                this_1.contributorsToAdd.push(newEntry);
            };
            var this_1 = this;
            try {
                for (var selections_1 = tslib_1.__values(selections), selections_1_1 = selections_1.next(); !selections_1_1.done; selections_1_1 = selections_1.next()) {
                    var contributor = selections_1_1.value;
                    _loop_1(contributor);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (selections_1_1 && !selections_1_1.done && (_a = selections_1.return)) _a.call(selections_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
        }
        console.info('DIALOG(Add contributors): done setting contributors to add to the book', this.contributorsToAdd);
        this.dialogRef.close();
    };
    /**
     * Close the dialog when the user clicks the "cancel" button, reinitialize the array that will be returned.
     */
    DialogContributorsComponent.prototype.closeDialog = function () {
        // User canceled, so clear the contributorsToAdd array
        this.contributorsToAdd = new Array();
        this.dialogRef.close();
    };
    /**
     * Save new contributor to db, then update the component's list of all contributors
     * @param newContributor
     */
    DialogContributorsComponent.prototype.saveNewContributor = function (newContributor) {
        var _this = this;
        // Save the new contributor via service, then add it to the multi-select
        this.contributorService.saveContributor(newContributor)
            .subscribe(function (contributor) {
            _this.lastNewContributor = contributor;
            _this.selectedContributors.push({
                option: contributor.id,
                displayText: contributor.lastName + ', ' + contributor.firstName + ' ' + contributor.middleName
            });
            // Reassign the array to a new reference so angular can detect changes in the child component
            _this.selectedContributors = _this.selectedContributors.slice();
            _this.contributorService.getContributors().subscribe(function (contributors) {
                _this.allContributors = contributors;
                _this.isUpdating = false;
            });
        });
    };
    ////////////
    // EVENTS //
    ////////////
    /**
     * Displays the second-level of dialog to allow creation of brand new contributors.
     */
    DialogContributorsComponent.prototype.showCreateContributor = function () {
        var _this = this;
        var newContributor = new ContributorPersonalInfo();
        // Create new dialog with data that matches ContributorDataInterface
        var dialogRef = this.dialog.open(DialogNewContributorComponent, {
            width: '80%',
            data: {
                isNewContributor: true,
                contributor: newContributor
            }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === -1) {
                return;
            }
            _this.isUpdating = true;
            _this.saveNewContributor(result);
        });
    };
    return DialogContributorsComponent;
}());
export { DialogContributorsComponent };
