import * as tslib_1 from "tslib";
import { OnInit, ElementRef, EventEmitter } from '@angular/core';
export var KEY;
(function (KEY) {
    KEY["UP"] = "ArrowUp";
    KEY["DOWN"] = "ArrowDown";
    KEY["LEFT"] = "ArrowLeft";
    KEY["RIGHT"] = "ArrowRight";
    KEY["SPACE"] = "Space";
    KEY["ENTER"] = "Enter";
    KEY["ESC"] = "Escape";
    KEY["DEL"] = "Backspace";
})(KEY || (KEY = {}));
/**
 * Multi-select dropdown component. Allows users to filter a list of dropdown items by text and add one or more items to
 * a list of selections.
 *
 *               A note about change detection: This component's "Array" type inputs (e.g. selectionsToReturn). Angular change detection does not compare object
 *               *content* when determining if an input changed. It only tracks the object's reference. Therefore,
 *               changes to these inputs will not not be reflected on Array.push(). Instead, you have to manually force the
 *               update by creating a new array reference (by copying it, for example: theArrayInput = theArrayInput.slice())
 * @example
 * <app-multi-select
 *               [id]="unique id for the input field so it can be labelled properly"
 *               [ariaLabel]="screen reader-only label for the input"
 *               [label]="Type to filter options" // A label/placeholder for the input field
 *               [selectionsToReturn]="" // Items in the options list that should appear as already-selected
 *               [hasFilter]="true" // Set true if users should be able to filter the list by text input
 *               [multiSelectOptions]="Array<IMultiSelectOptions>" // Required array of IMultiselectOptions objects
 *               [filteredOptionsSubject]="{ list: Subject<Array<IMultiSelectOptions>>, resetSelections: boolean }"
 *               // Optional Subject observable that expects a list of options upon subscribe events, as well
 *               // as a flag telling the multi-select whether or not to reset current selections
 *               (onDestroy)="updateParentData($event)" // Callback to fire when the component is removed from the DOM (e.g. if the component exists inside a dialog that was closed)
 *               (onSelectionsChanged)="updateParentData($event)"> // Callback fired whenever the multi-select's "selectionsToReturn" were changed by a multi-select method
 * </app-multi-select>
 */
var MultiSelectComponent = /** @class */ (function () {
    function MultiSelectComponent() {
        this.data = new Array();
        this._initHasFired = false;
        this.keyCode = 'Enter';
        this.onDestroy = new EventEmitter();
        this.onSelectionsChanged = new EventEmitter();
    }
    Object.defineProperty(MultiSelectComponent.prototype, "in", {
        // Using a custom setter so we can perform additional actions
        // when this input changes
        set: function (val) {
            this._multiSelectOptions = val;
            this.multiSelectOptionsInputUpdate();
        },
        enumerable: true,
        configurable: true
    });
    /////////////////////
    // LIFECYCLE HOOKS //
    /////////////////////
    MultiSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isActive = false;
        // If we have options
        if (this._multiSelectOptions !== undefined) {
            // Clone the input array so we don't have any pesky references
            this.data = JSON.parse(JSON.stringify(this._multiSelectOptions));
        }
        if (this.filteredOptionsSubject) {
            this.filteredOptionsSubject.subscribe(function (options) {
                _this.data = options['list'];
                if (options['resetSelections']) {
                    _this.selectionsToReturn = new Array();
                }
            });
        }
        if (this.selectionsToReturn !== undefined) {
            setTimeout(function () {
                _this.setLastIndexes();
            }, 1000);
        }
        else {
            this.selectionsToReturn = new Array();
        }
        this._initHasFired = true;
    };
    MultiSelectComponent.prototype.multiSelectOptionsInputUpdate = function () {
        var _this = this;
        var e_1, _a, e_2, _b;
        // If ngOnInit hasn't executed yet
        if (!this._initHasFired) {
            // we're done here
            return;
        }
        // If this componenent wasn't initialized with the filteredOptionsSubject input parameter
        if (!this.filteredOptionsSubject) {
            // Clone the input array so we don't have any pesky references
            this.data = JSON.parse(JSON.stringify(this._multiSelectOptions));
        }
        // We're going to rebuild the array of selected options, 
        // in case any options were renamed or deleted in this.data
        var tempSelectedSubjects = new Array();
        try {
            // For each option that was selected prior to this update
            for (var _c = tslib_1.__values(this.selectionsToReturn), _d = _c.next(); !_d.done; _d = _c.next()) {
                var selectedOption = _d.value;
                try {
                    // For each of all options
                    for (var _e = tslib_1.__values(this.data), _f = _e.next(); !_f.done; _f = _e.next()) {
                        var allOption = _f.value;
                        // If we found our selected option
                        if (allOption.option === selectedOption.option) {
                            // Add to our new list of selected (with a potential new name)
                            tempSelectedSubjects.push(allOption);
                            break;
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        // Update the selected options
        this.selectionsToReturn = JSON.parse(JSON.stringify(tempSelectedSubjects));
        // Set the lastIndex property on the selected options
        // and remove those options from this.data
        this.setLastIndexes();
        // Need setTimeout() to avoid an "expression has changed after it was checked" error
        // because selectionsToReturn is fed back into this component by the parent as an input,
        // so we're not allowed to modify it on this cycle
        setTimeout(function () {
            // Notify the parent component that  the selected options have changed
            _this.onSelectionsChanged.emit(_this.selectionsToReturn);
        }, 0);
    };
    MultiSelectComponent.prototype.ngOnDestroy = function () {
        // When this component is destroyed, we should 
        // return the user's selections to the parent component
        this.onDestroy.emit(this.selectionsToReturn);
        if (this.filteredOptionsSubject) {
            this.filteredOptionsSubject.unsubscribe();
        }
    };
    /**
     * Grab the index where the item last existed so we can kind of approximate
     * where it should go back to if the used unselects it.
     */
    MultiSelectComponent.prototype.setLastIndexes = function () {
        var _this = this;
        // If we were given a list of selected options
        if (this.selectionsToReturn === undefined) {
            return;
        }
        if (this.selectionsToReturn.length > 0) {
            // If some selections already exist, display them and remove them from the list of options
            // For every given selected option
            this.selectionsToReturn.forEach(function (item) {
                // Get the location of the selected option in the data array
                var index = _this.data.indexOf(_this.data.find(function (entry) {
                    if (entry.option === item.option) {
                        return entry.option;
                    }
                }));
                // If we found the location of this selected option in our list of searchable options
                if (index > -1) {
                    // Remove the selected option from the searchable options
                    _this.data.splice(index, 1);
                    // Track where this option existed in the data array
                    item.lastIndex = index;
                }
            });
        }
    };
    ////////////
    // EVENTS //
    ////////////
    /**
     * Removes hidden attribute from the dropdown/select area.
     */
    MultiSelectComponent.prototype.setActive = function () {
        this.isActive = true;
    };
    /**
     * Toggles hidden attribute on the dropdown/select area.
     */
    MultiSelectComponent.prototype.toggleActive = function () {
        this.isActive ? this.isActive = false : this.isActive = true;
    };
    /**
     * Listen for clicks outside of the multi-select and close it if it should be inactive
     * @param event
     */
    MultiSelectComponent.prototype.onClick = function (event) {
        if (this.root.nativeElement.contains(event.target) && !this.upArrow.nativeElement.contains(event.target)) {
            this.isActive = true;
        }
        else if (!this.root.nativeElement.contains(event.target)) {
            this.isActive = false;
        }
    };
    /**
     * Respond to key presses within the select list to navigate among them or add them to the selections
     * Note: This one uses keydown instead of keyup in order to stop the space bar from scrolling the page
     * @param {KeyboardEvent} event Javascript event that can give us the key pressed
     * @param {IMultiSelectOptions} option Multi-select option
     * @param {number} index The index of the option on which a key was pressed
     */
    MultiSelectComponent.prototype.addOnKeydown = function (event, option, index) {
        var previous = event.target['previousSibling'];
        var next = event.target['nextSibling'];
        // If space/enter, add the focused option to the selections list
        if (event.code == KEY.SPACE || event.code == KEY.ENTER) {
            event.preventDefault();
            this.addSelection(option);
        }
        else if (event.code == KEY.ESC) {
            this.hasFilter ? this.filter.nativeElement.focus() : this.noFilter.nativeElement.focus();
        }
        // If first item in list and up/left arrow pressed: focus the search filter input
        // Otherwise, check if previous/next sibling is a div and navigate based on which key was pressed
        if (event.code == KEY.UP || event.code == KEY.LEFT) {
            if (index === 0) {
                this.hasFilter ? this.filter.nativeElement.focus() : this.noFilter.nativeElement.focus();
            }
            else if (previous.nodeName == 'DIV') {
                previous.focus();
            }
        }
        else if (next.nodeName == 'DIV' && (event.code == KEY.DOWN || event.code == KEY.RIGHT)) {
            next.focus();
        }
    };
    /**
     * If user presses down/right while focusing search filter, navigate to the first item in the list
     * @param {KeyboardEvent} event The keyup event
     */
    MultiSelectComponent.prototype.onInputKeyup = function (event) {
        if (event.code == KEY.DOWN || event.code == KEY.RIGHT) {
            this.listContainer.nativeElement.firstElementChild.focus();
        }
        // If backspace was pressed and search text is empty (no characters to delete)
        if (event.code === KEY.DEL && this.searchText !== undefined && this.searchText.length === 0) {
            // Hide the dropdown
            this.isActive = false;
        }
    };
    /**
     * When interacting with dropUp or dropDown arrows, toggle active state and keep focus on the button
     * @param {KeyboardEvent} event Keydown event
     */
    MultiSelectComponent.prototype.onArrowButton = function (event) {
        var _this = this;
        if (event.code == KEY.ENTER || event.code == KEY.SPACE) {
            event.preventDefault();
            this.toggleActive();
            // Make sure focus doesn't move away from this control
            if (event.target == this.upArrow.nativeElement) {
                setTimeout(function () {
                    _this.downArrow.nativeElement.focus();
                }, 100);
            }
            else if (event.target == this.downArrow.nativeElement) {
                setTimeout(function () {
                    _this.hasFilter ? _this.filter.nativeElement.focus() : _this.noFilter.nativeElement.focus();
                }, 100);
            }
        }
    };
    /**
     * Allow deleting selection with keyboard (backspace or enter keys)
     * @param {KeyboardEvent} event
     * @param {IMultiSelectOptions} item
     */
    MultiSelectComponent.prototype.onSelectedDelete = function (event, item) {
        if (event.code == KEY.DEL || event.code == KEY.ENTER) {
            this.removeSelection(item);
        }
    };
    /**
     * Adds a user-selected item to the list of options to return to the parent component.
     * @param {IMultiSelectOptions} item An item from the available options
     */
    MultiSelectComponent.prototype.addSelection = function (item) {
        // Remove from options list
        var index = this.data.indexOf(item);
        item.lastIndex = index;
        if (index != -1) {
            this.data.splice(index, 1);
        }
        // Add to return data and notify parent that selections changed    
        this.selectionsToReturn.push(item);
        this.onSelectionsChanged.emit(this.selectionsToReturn);
        // Re-focus on input and clear it
        this.filter.nativeElement.focus();
        this.searchText = '';
    };
    /**
     * Upon user action, removes an item from the list of options to return to the parent component.
     * @param {IMultiSelectOptions} item An item from the list of options to return
     */
    MultiSelectComponent.prototype.removeSelection = function (item) {
        // Remove from return data
        var index = this.selectionsToReturn.indexOf(item);
        if (index != -1) {
            this.selectionsToReturn.splice(index, 1);
            // Notify parent that selections changed
            this.onSelectionsChanged.emit(this.selectionsToReturn);
        }
        // Add to options list
        this.data.splice(item.lastIndex, 0, item);
        // Re-focus input
        this.filter.nativeElement.focus();
    };
    return MultiSelectComponent;
}());
export { MultiSelectComponent };
