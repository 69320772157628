import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TreeNode, ITreeOptions } from 'angular-tree-component';
import { MatDialog } from '@angular/material/dialog';
import { DialogBibliographyBookComponent } from 'src/app/components/dialog-bibliography-book/dialog-bibliography-book.component';
//classes
import { Bibliography, BibliographyAgeGrade, BibliographiesAgeGrades, NestedCategories } from 'src/app/classes/Bibliography';
var EditBibliographyComponent = /** @class */ (function () {
    function EditBibliographyComponent(router, route, bibliographyService, titleService, dialog) {
        var _this = this;
        this.router = router;
        this.route = route;
        this.bibliographyService = bibliographyService;
        this.titleService = titleService;
        this.dialog = dialog;
        this.loading = true;
        this.hasGetBibliographyError = false;
        this.richTextHasError = false;
        this.saveSuccessful = false;
        this.saveError = false;
        this.newBibliography = false;
        this.blankCategoryName = "";
        this.treeOptions = {
            idField: 'id',
            displayField: 'description',
            allowDrag: true,
            allowDrop: function (element, _a) {
                var parent = _a.parent, index = _a.index;
                return parent.level <= 1;
            }
        };
        //////////////////////////
        // Age/Grade checkboxes //
        //////////////////////////  
        this.allAgeGrades = new Array();
        this.selectedAgeGrades = new Array();
        //////////////
        // Tiny MCE //
        //////////////
        this.isTinyMCEAlive = false;
        this.tinyMCEInput = '';
        this.tinyMCELimit = 4000;
        this.tinyMCEHasError = false;
        this.TINY_MCE_REGEX = /&nbsp;/;
        //tree specific events
        this.onTreeEvent = function ($event) {
            if ($event.eventName == "activate") {
                $event.node.data.showBooks = true;
            }
            if ($event.eventName == "deactivate") {
                $event.node.data.showBooks = false;
            }
        };
        this.deleteBooks = function (node) {
            //loop backwards to prevent altering the indices when splicing
            var i = node.data.booksForBibliographyView.length;
            while (i--) {
                if (node.data.booksForBibliographyView[i].remove) {
                    node.data.booksForBibliographyView.splice(i, 1);
                }
            }
        };
        this.removeCategory = function (node) {
            var _a, e_1, _b;
            //if parent, promote any children to primary status first before removing (insert at same level) - NOTE - this logic assumes only one level of subcategory
            if (node.level == 1) {
                for (var parentIndex = 0; parentIndex < _this.bibliography.nestedCategories.length; parentIndex++) {
                    if (_this.bibliography.nestedCategories[parentIndex].id == node.data.id) {
                        //add children to parent array below the parent
                        (_a = _this.bibliography.nestedCategories).splice.apply(_a, tslib_1.__spread([parentIndex + 1, 0], _this.bibliography.nestedCategories[parentIndex].children)); //... is the spread operator https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Spread_syntax
                        //then remove parent
                        _this.bibliography.nestedCategories.splice(parentIndex, 1);
                    }
                }
            }
            //otherwise if child just remove it
            if (node.level == 2) {
                try {
                    for (var _c = tslib_1.__values(_this.bibliography.nestedCategories), _d = _c.next(); !_d.done; _d = _c.next()) { //find parent
                        var parent_1 = _d.value;
                        if (parent_1.id == node.parent.data.id) {
                            for (var childIndex = 0; childIndex < parent_1.children.length; childIndex++) {
                                if (parent_1.children[childIndex].id == node.data.id) {
                                    parent_1.children.splice(childIndex, 1);
                                }
                            }
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            _this.bibliography.nestedCategories = tslib_1.__spread(_this.bibliography.nestedCategories); //found this in the demo, this forces the nestable component to re-render
        };
        //fire the book add model
        this.addBook = function (node) {
            // Create new dialog with data that matches IPublisherData
            var dialogRef = _this.dialog.open(DialogBibliographyBookComponent, {
                width: '60%',
                data: {
                    category: node.data
                }
            });
            dialogRef.afterClosed().subscribe(function (result) {
                var _a;
                // Check for cancellation
                if (result === -1 || result === undefined) {
                    return;
                }
                (_a = node.data.booksForBibliographyView).push.apply(_a, tslib_1.__spread(result));
            });
        };
    }
    EditBibliographyComponent.prototype.ngOnInit = function () {
        this.GetBibliography();
    };
    EditBibliographyComponent.prototype.setTitle = function (title) {
        this.titleService.setTitle(title);
    };
    //////////
    // Load //
    //////////
    EditBibliographyComponent.prototype.GetBibliography = function () {
        var _this = this;
        // Get bibliography ID from route and cast as number
        var id = +this.route.snapshot.paramMap.get('id');
        if (id === 0) {
            //create new bib
            this.bibliography = new Bibliography();
            //add one blank category
            var blankCategory = new NestedCategories();
            blankCategory.description = "Default Category";
            blankCategory.order = -1;
            this.blankCategoryName = "";
            this.bibliography.nestedCategories.push(blankCategory);
            this.getAgeGrades();
            this.loading = false;
            this.newBibliography = true;
            this.setTitle('New booklist | CCBC Book Search');
        }
        else {
            this.bibliographyService.getBibliographyById(id).subscribe(function (bib) {
                _this.bibliography = bib;
                _this.getAgeGrades();
                _this.loading = false;
                _this.blankCategoryName = "";
                _this.setTitle('Edit booklist | CCBC Book Search');
            }, function (err) {
                _this.errorId = id;
                _this.hasGetBibliographyError = true;
            });
        }
    };
    EditBibliographyComponent.prototype.getAgeGrades = function () {
        var _this = this;
        this.bibliographyService.getAllAgeGrades().subscribe(function (ageGrades) {
            if (ageGrades != undefined && ageGrades.length > 0) {
                _this.populateAgeGrades(ageGrades);
            }
        });
    };
    EditBibliographyComponent.prototype.populateAgeGrades = function (ageGrades) {
        var _this = this;
        this.selectedAgeGrades = this.bibliography.bibliographiesAgeGrades;
        this.allAgeGrades = ageGrades;
        this.allAgeGrades.forEach(function (item) {
            if (_this.selectedAgeGrades.filter(function (age) { return age.bibliographyAgeGradeId === item.ageGradeId; }).length > 0) {
                item.selected = true;
            }
            else {
                item.selected = false;
            }
        });
    };
    EditBibliographyComponent.prototype.onClickAgeGrade = function () {
        var _this = this;
        var updatedAgeGrades = new Array();
        this.allAgeGrades.forEach(function (all) {
            if (all.selected) {
                var updatedAgeGrade = {
                    bibliographyId: _this.bibliography.id,
                    bibliographyAgeGradeId: all.ageGradeId,
                    bibliographyAgeGrade: null
                };
                updatedAgeGrades.push(updatedAgeGrade);
            }
        });
        this.bibliography.bibliographiesAgeGrades = updatedAgeGrades;
        this.selectedAgeGrades = updatedAgeGrades;
    };
    /**
     * Set initial input value equal to back end data
     */
    EditBibliographyComponent.prototype.initTinyMCE = function () {
        if (this.isTinyMCEAlive) {
            return;
        }
        if (this.bibliography.introduction != null) {
            this.tinyMCEInput = this.bibliography.introduction;
        }
        this.tinyMCEHasError = this.checkTinyMCEHasError();
        this.isTinyMCEAlive = true;
    };
    /**
     * Get length of editor input after stripping non-breaking spaces. We should not get any of these
     * if input validation works, but we should check for them just in case.
     */
    EditBibliographyComponent.prototype.getTinyMCEInputLength = function () {
        var length = this.tinyMCEInput.replace(this.TINY_MCE_REGEX, ' ').trim().length;
        return length;
    };
    /**
     * Check if input length is within limit
     */
    EditBibliographyComponent.prototype.checkTinyMCEHasError = function () {
        var length = this.getTinyMCEInputLength();
        return length > this.tinyMCELimit;
    };
    /**
     * Check for length within constraints and update model data to match editor input
     * (minus auto-inserted non-breaking spaces to save on character limit)
     */
    EditBibliographyComponent.prototype.onTinyMCEInput = function () {
        // Store rich text but replace '&nbsp;' to save on characters
        this.bibliography.introduction = this.tinyMCEInput.replace(this.TINY_MCE_REGEX, ' ').trim();
        // Update UI helper and bubble up event to disable saving the book
        this.tinyMCEHasError = this.checkTinyMCEHasError();
    };
    ///////////
    // Save! //
    ///////////
    EditBibliographyComponent.prototype.onSave = function () {
        var _this = this;
        this.saveSuccessful = false;
        this.saveError = false;
        this.bibliographyService.saveBibliography(this.bibliography).subscribe(function (bib) {
            _this.saveSuccessful = true;
            _this.saveError = false;
            _this.lastSavedDate = new Date();
            //reload with id in route
            if (_this.newBibliography) {
                _this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; }; //https://stackoverflow.com/questions/52389376/angular-6-how-to-reload-current-page
                _this.router.onSameUrlNavigation = 'reload';
                _this.router.navigate(['/bibliography/' + bib.id]);
            }
        }, function (err) {
            _this.saveSuccessful = false;
            _this.saveError = true;
        });
    };
    //Manage Categories
    EditBibliographyComponent.prototype.addCategory = function (categoryName) {
        //if category with empty name, ignore add
        if (categoryName.trim().length === 0) {
            this.blankCategoryName = "";
            return;
        }
        var blankCategory = new NestedCategories();
        blankCategory.description = categoryName;
        blankCategory.order = 1; //not really used by front-end component, only important for saving back to db - we will calculate on backend
        this.bibliography.nestedCategories.push(blankCategory);
        this.bibliography.nestedCategories = tslib_1.__spread(this.bibliography.nestedCategories); //found this in the demo, this forces the nestable component to re-render
        this.blankCategoryName = "";
    };
    return EditBibliographyComponent;
}());
export { EditBibliographyComponent };
