import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var DiversitySubjectComponent = /** @class */ (function () {
    function DiversitySubjectComponent(bookService) {
        this.bookService = bookService;
        //diversity subject - Heritage setting
        this.AllDiversityHeritages = new Array();
        this.diversityHeritageOptions = new Array();
        this.selectedDiversityHeritages = new Array();
        this.booksHeritageToSave = new Array();
        //diversity subject - Religion
        this.AllDiversityReligions = new Array();
        this.diversityReligionOptions = new Array();
        this.selectedDiversityReligions = new Array();
        this.booksReligionToSave = new Array();
        //diversity subject - LGBTQ
        this.AllDiversityLGBTQ = new Array();
        this.diversityLGBTQOptions = new Array();
        this.selectedDiversityLGBTQ = new Array();
        this.booksLGBTQToSave = new Array();
        //diversity subject - Disabilities
        this.AllDiversityDisability = new Array();
        this.diversityDisabilityOptions = new Array();
        this.selectedDiversityDisability = new Array();
        this.booksDisabilityToSave = new Array();
        //secondary diversity
        this.AllSecondaryDiversity = new Array();
        this.diversitySecondaryOptions = new Array();
        this.selectedSecondaryDiversity = new Array();
        this.booksSecondaryToSave = new Array();
        //gender marker
        this.AllDiversityGenderMarker = new Array();
        this.genderMarkerOptions = new Array();
        this.selectedGenderMarker = new Array();
        this.booksGenderMarkerToSave = new Array();
    }
    /**
       * Transform exististing publishers into data consumable by the multi-select
       * @param {Array<BooksHeritages>} heritages Publishers already associated with this book
       */
    DiversitySubjectComponent.prototype.transformBookHeritages = function (heritages) {
        var _this = this;
        heritages.forEach(function (item) {
            var data = {
                option: item.heritageId,
                displayText: item.heritage.name
            };
            _this.selectedDiversityHeritages.push(data);
        });
    };
    DiversitySubjectComponent.prototype.transformBookReligions = function (religions) {
        var _this = this;
        religions.forEach(function (item) {
            var data = {
                option: item.religionId,
                displayText: item.religion.name
            };
            _this.selectedDiversityReligions.push(data);
        });
    };
    DiversitySubjectComponent.prototype.transformBookLgbtqs = function (lgbtqs) {
        var _this = this;
        lgbtqs.forEach(function (item) {
            var data = {
                option: item.lgbtqId,
                displayText: item.lgbtq.name
            };
            _this.selectedDiversityLGBTQ.push(data);
        });
    };
    DiversitySubjectComponent.prototype.transformBookDisabilities = function (disabilities) {
        var _this = this;
        disabilities.forEach(function (item) {
            var data = {
                option: item.disabilityId,
                displayText: item.disability.name
            };
            _this.selectedDiversityDisability.push(data);
        });
    };
    DiversitySubjectComponent.prototype.transformBookSecondary = function (secondaries) {
        var _this = this;
        secondaries.forEach(function (item) {
            var data = {
                option: item.secondaryId,
                displayText: item.secondary.name
            };
            _this.selectedSecondaryDiversity.push(data);
        });
    };
    DiversitySubjectComponent.prototype.transformBookGenderMarker = function (genderMarkers) {
        var _this = this;
        genderMarkers.forEach(function (item) {
            var data = {
                option: item.genderMarkerLookupId,
                displayText: item.genderMarkerLookup.name
            };
            _this.selectedGenderMarker.push(data);
        });
    };
    DiversitySubjectComponent.prototype.getAllDiversityHeritages = function () {
        var _this = this;
        this.bookService.getAllHeritages().subscribe(function (heritages) {
            if (heritages != undefined && heritages.length > 0) {
                _this.AllDiversityHeritages = heritages;
                _this.assembleHeritageOptions(heritages);
            }
        });
    };
    DiversitySubjectComponent.prototype.getAllDiversityReligions = function () {
        var _this = this;
        this.bookService.getAllReligions().subscribe(function (religions) {
            if (religions != undefined && religions.length > 0) {
                _this.AllDiversityHeritages = religions;
                _this.assembleReligionOptions(religions);
            }
        });
    };
    DiversitySubjectComponent.prototype.getAllDiversityLgbtqs = function () {
        var _this = this;
        this.bookService.getAllLgbtqs().subscribe(function (lgbtqs) {
            if (lgbtqs != undefined && lgbtqs.length > 0) {
                _this.AllDiversityLGBTQ = lgbtqs;
                _this.assembleLgbtqOptions(lgbtqs);
            }
        });
    };
    DiversitySubjectComponent.prototype.getAllDiversityDisabilities = function () {
        var _this = this;
        this.bookService.getAllDisabilities().subscribe(function (disabilities) {
            if (disabilities != undefined && disabilities.length > 0) {
                _this.AllDiversityDisability = disabilities;
                _this.assembleDisabilityOptions(disabilities);
            }
        });
    };
    DiversitySubjectComponent.prototype.getAllSecondaries = function () {
        var _this = this;
        this.bookService.getAllSecondaries().subscribe(function (secondaries) {
            if (secondaries != undefined && secondaries.length > 0) {
                _this.AllSecondaryDiversity = secondaries;
                _this.assembleSecondaryOptions(secondaries);
            }
        });
    };
    DiversitySubjectComponent.prototype.getAllGenderMarkers = function () {
        var _this = this;
        this.bookService.getAllGenderMarkers().subscribe(function (genderMarkers) {
            if (genderMarkers != undefined && genderMarkers.length > 0) {
                _this.AllDiversityGenderMarker = genderMarkers;
                _this.assembleGenderMarkerOptions(genderMarkers);
            }
        });
    };
    DiversitySubjectComponent.prototype.assembleHeritageOptions = function (heritages) {
        var e_1, _a;
        try {
            for (var heritages_1 = tslib_1.__values(heritages), heritages_1_1 = heritages_1.next(); !heritages_1_1.done; heritages_1_1 = heritages_1.next()) {
                var heritage = heritages_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: heritage.id,
                    displayText: heritage.name
                };
                this.diversityHeritageOptions.push(data);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (heritages_1_1 && !heritages_1_1.done && (_a = heritages_1.return)) _a.call(heritages_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.diversityHeritageOptions = this.diversityHeritageOptions.slice();
    };
    DiversitySubjectComponent.prototype.assembleReligionOptions = function (religions) {
        var e_2, _a;
        try {
            for (var religions_1 = tslib_1.__values(religions), religions_1_1 = religions_1.next(); !religions_1_1.done; religions_1_1 = religions_1.next()) {
                var religion = religions_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: religion.id,
                    displayText: religion.name
                };
                this.diversityReligionOptions.push(data);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (religions_1_1 && !religions_1_1.done && (_a = religions_1.return)) _a.call(religions_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        this.diversityReligionOptions = this.diversityReligionOptions.slice();
    };
    DiversitySubjectComponent.prototype.assembleLgbtqOptions = function (lgbtqs) {
        var e_3, _a;
        try {
            for (var lgbtqs_1 = tslib_1.__values(lgbtqs), lgbtqs_1_1 = lgbtqs_1.next(); !lgbtqs_1_1.done; lgbtqs_1_1 = lgbtqs_1.next()) {
                var lgbtq = lgbtqs_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: lgbtq.id,
                    displayText: lgbtq.name
                };
                this.diversityLGBTQOptions.push(data);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (lgbtqs_1_1 && !lgbtqs_1_1.done && (_a = lgbtqs_1.return)) _a.call(lgbtqs_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        this.diversityLGBTQOptions = this.diversityLGBTQOptions.slice();
    };
    DiversitySubjectComponent.prototype.assembleDisabilityOptions = function (disabilities) {
        var e_4, _a;
        try {
            for (var disabilities_1 = tslib_1.__values(disabilities), disabilities_1_1 = disabilities_1.next(); !disabilities_1_1.done; disabilities_1_1 = disabilities_1.next()) {
                var disability = disabilities_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: disability.id,
                    displayText: disability.name
                };
                this.diversityDisabilityOptions.push(data);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (disabilities_1_1 && !disabilities_1_1.done && (_a = disabilities_1.return)) _a.call(disabilities_1);
            }
            finally { if (e_4) throw e_4.error; }
        }
        this.diversityDisabilityOptions = this.diversityDisabilityOptions.slice();
    };
    DiversitySubjectComponent.prototype.assembleSecondaryOptions = function (secondaries) {
        var e_5, _a;
        try {
            for (var secondaries_1 = tslib_1.__values(secondaries), secondaries_1_1 = secondaries_1.next(); !secondaries_1_1.done; secondaries_1_1 = secondaries_1.next()) {
                var secondary = secondaries_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: secondary.id,
                    displayText: secondary.name
                };
                this.diversitySecondaryOptions.push(data);
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (secondaries_1_1 && !secondaries_1_1.done && (_a = secondaries_1.return)) _a.call(secondaries_1);
            }
            finally { if (e_5) throw e_5.error; }
        }
        this.diversitySecondaryOptions = this.diversitySecondaryOptions.slice();
    };
    DiversitySubjectComponent.prototype.assembleGenderMarkerOptions = function (gendermarkers) {
        var e_6, _a;
        try {
            for (var gendermarkers_1 = tslib_1.__values(gendermarkers), gendermarkers_1_1 = gendermarkers_1.next(); !gendermarkers_1_1.done; gendermarkers_1_1 = gendermarkers_1.next()) {
                var gendermarker = gendermarkers_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: gendermarker.id,
                    displayText: gendermarker.name
                };
                this.genderMarkerOptions.push(data);
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (gendermarkers_1_1 && !gendermarkers_1_1.done && (_a = gendermarkers_1.return)) _a.call(gendermarkers_1);
            }
            finally { if (e_6) throw e_6.error; }
        }
        this.genderMarkerOptions = this.genderMarkerOptions.slice();
    };
    DiversitySubjectComponent.prototype.setSelectedHeritages = function (heritages) {
        // If the user removed all publishers, mark them all for deletion
        this.selectedDiversityHeritages = heritages;
        this.selectedDiversityHeritages = this.selectedDiversityHeritages.slice();
        this.updateBooksHeritageToSave();
    };
    DiversitySubjectComponent.prototype.setSelectedReligions = function (religions) {
        // If the user removed all publishers, mark them all for deletion
        this.selectedDiversityReligions = religions;
        this.selectedDiversityReligions = this.selectedDiversityReligions.slice();
        this.updateBooksReligionToSave();
    };
    DiversitySubjectComponent.prototype.setSelectedLgbtqs = function (lgbtqs) {
        // If the user removed all publishers, mark them all for deletion
        this.selectedDiversityLGBTQ = lgbtqs;
        this.selectedDiversityLGBTQ = this.selectedDiversityLGBTQ.slice();
        this.updateBooksLGBTQToSave();
    };
    DiversitySubjectComponent.prototype.setSelectedDisabilities = function (disabilities) {
        // If the user removed all publishers, mark them all for deletion
        this.selectedDiversityDisability = disabilities;
        this.selectedDiversityDisability = this.selectedDiversityDisability.slice();
        this.updateBooksDisabilityToSave();
    };
    DiversitySubjectComponent.prototype.setSelectedSecondaries = function (secondaries) {
        // If the user removed all publishers, mark them all for deletion
        this.selectedSecondaryDiversity = secondaries;
        this.selectedSecondaryDiversity = this.selectedSecondaryDiversity.slice();
        this.updateBooksSecondaryToSave();
    };
    DiversitySubjectComponent.prototype.setSelectedGenderMarkers = function (gendermarkers) {
        // If the user removed all publishers, mark them all for deletion
        this.selectedGenderMarker = gendermarkers;
        this.selectedGenderMarker = this.selectedGenderMarker.slice();
        this.updateBooksGenderMarkerToSave();
    };
    DiversitySubjectComponent.prototype.updateBooksHeritageToSave = function () {
        var _this = this;
        // Transform multi-select data into book publishers
        var updatedHeritages = new Array();
        this.selectedDiversityHeritages.forEach(function (item) {
            var bookheritage = {
                bookId: _this.model.id,
                heritageId: item.option,
                heritage: null // this.AllDiversityHeritages.find(entry => entry.id === item.option)
            };
            updatedHeritages.push(bookheritage);
        });
        this.model.booksHeritages = updatedHeritages;
    };
    DiversitySubjectComponent.prototype.updateBooksReligionToSave = function () {
        var _this = this;
        // Transform multi-select data into book publishers
        var updatedReligions = new Array();
        this.selectedDiversityReligions.forEach(function (item) {
            var bookReligion = {
                bookId: _this.model.id,
                religionId: item.option,
                religion: null
            };
            updatedReligions.push(bookReligion);
        });
        this.model.booksReligions = updatedReligions;
    };
    DiversitySubjectComponent.prototype.updateBooksLGBTQToSave = function () {
        var _this = this;
        // Transform multi-select data into book publishers
        var updatedLgbtqs = new Array();
        this.selectedDiversityLGBTQ.forEach(function (item) {
            var bookLgbtq = {
                bookId: _this.model.id,
                lgbtqId: item.option,
                lgbtq: null //this.AllDiversityLGBTQ.find(entry => entry.id === item.option)
            };
            updatedLgbtqs.push(bookLgbtq);
        });
        this.model.booksLgbtqs = updatedLgbtqs;
    };
    DiversitySubjectComponent.prototype.updateBooksDisabilityToSave = function () {
        var _this = this;
        // Transform multi-select data into book publishers
        var updatedDisabilities = new Array();
        this.selectedDiversityDisability.forEach(function (item) {
            var bookDisability = {
                bookId: _this.model.id,
                disabilityId: item.option,
                //book: this.model,
                disability: null //this.AllDiversityDisability.find(entry => entry.id === item.option)
            };
            updatedDisabilities.push(bookDisability);
        });
        this.model.booksDisabilities = updatedDisabilities;
    };
    DiversitySubjectComponent.prototype.updateBooksSecondaryToSave = function () {
        var _this = this;
        // Transform multi-select data into book publishers
        var updatedSecondaries = new Array();
        this.selectedSecondaryDiversity.forEach(function (item) {
            var booksecondary = {
                bookId: _this.model.id,
                secondaryId: item.option,
                //book: this.model,
                secondary: null //this.AllSecondaryDiversity.find(entry => entry.id === item.option)
            };
            updatedSecondaries.push(booksecondary);
        });
        this.model.booksSecondaries = updatedSecondaries;
    };
    DiversitySubjectComponent.prototype.updateBooksGenderMarkerToSave = function () {
        var _this = this;
        // Transform multi-select data into book publishers
        var updatedGenderMarkers = new Array();
        this.selectedGenderMarker.forEach(function (item) {
            var bookGendermarker = {
                bookId: _this.model.id,
                genderMarkerLookupId: item.option,
                //book: this.model,
                genderMarkerLookup: null //this.AllDiversityGenderMarker.find(entry => entry.id === item.option)
            };
            updatedGenderMarkers.push(bookGendermarker);
        });
        this.model.booksGenderMarkers = updatedGenderMarkers;
    };
    DiversitySubjectComponent.prototype.ngOnInit = function () {
        this.getAllDiversityHeritages();
        this.transformBookHeritages(this.model.booksHeritages);
        this.getAllDiversityReligions();
        this.transformBookReligions(this.model.booksReligions);
        this.getAllDiversityLgbtqs();
        this.transformBookLgbtqs(this.model.booksLgbtqs);
        this.getAllDiversityDisabilities();
        this.transformBookDisabilities(this.model.booksDisabilities);
        this.getAllSecondaries();
        this.transformBookSecondary(this.model.booksSecondaries);
        this.getAllGenderMarkers();
        this.transformBookGenderMarker(this.model.booksGenderMarkers);
    };
    return DiversitySubjectComponent;
}());
export { DiversitySubjectComponent };
