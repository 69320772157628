import * as tslib_1 from "tslib";
import { OnInit, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormArray } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { CCBCSubject } from 'src/app/classes/Recommendation';
/**
 * Child component that displays a confirmation dialog when deleting a CCBC Subject.
 */
var DialogConfirmDeleteCCBCSubject = /** @class */ (function () {
    function DialogConfirmDeleteCCBCSubject(data) {
        this.data = data;
    }
    return DialogConfirmDeleteCCBCSubject;
}());
export { DialogConfirmDeleteCCBCSubject };
/**
 * Component that displays a list of CCBC Subjects and provides controls for creating, updating, and deleting these subjects.
 */
var DialogCCBCSubjectsComponent = /** @class */ (function () {
    function DialogCCBCSubjectsComponent(bookService, dialogRef, confirmDeleteDialog, el, data) {
        this.bookService = bookService;
        this.dialogRef = dialogRef;
        this.confirmDeleteDialog = confirmDeleteDialog;
        this.el = el;
        this.data = data;
        this.allSubjects = new Array();
        this.newSubjectName = new FormControl();
        this.isAddingNewSubject = false;
        this.crudCallIsPending = false;
        this.newSubjectName.setValidators([this.subjectExistsValidator(0)]);
        this.formGroupSubjectNames = new FormGroup({
            subjects: new FormArray([])
        });
    }
    DialogCCBCSubjectsComponent.prototype.onKeyUp = function () {
        this.closeIfWeCan();
    };
    DialogCCBCSubjectsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var e_1, _a;
        // Don't allow closing by normal means (clicking-away, pressing Escape)
        // We need to validate that it's safe to close, so we can pass the canonical
        // list of subjects back to the parent.
        this.dialogRef.disableClose = true;
        // If the backdrop around the dialog is clicked
        this.dialogRef.backdropClick().subscribe(function (_) {
            _this.closeIfWeCan();
        });
        if (this.data.allCCBCSubjects) {
            this.allSubjects = this.data.allCCBCSubjects;
            // We'll initially sort the list alphabetically to make it easy to find an entry for editing,
            // but that sorting won't be maintained as we don't want a table row to "jump" after the user renames an entry.
            // Likewise, we'll insert newly created entries at the top of the table, so the user can see that it was saved successfully
            this.allSubjects.sort(function (a, b) { return (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0); });
            try {
                // For each CCBC Subject
                for (var _b = tslib_1.__values(this.allSubjects), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var subject = _c.value;
                    // Create a form group
                    var subjectGroup = new FormGroup({
                        id: new FormControl(subject.ccbcSubjectId),
                        name: new FormControl(subject.name, [this.subjectExistsValidator(subject.ccbcSubjectId)]),
                        editing: new FormControl(false),
                        originalName: new FormControl(subject.name)
                    });
                    // Add the form group, respresenting a single CCBCSubject, to our form array 
                    this.formGroupSubjectNames.controls.subjects.push(subjectGroup);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
    DialogCCBCSubjectsComponent.prototype.addNewSubjectClick = function () {
        var _this = this;
        this.isAddingNewSubject = !this.isAddingNewSubject;
        // Wait for the field to be rendered, then focus on it
        setTimeout(function () {
            _this.inputNewSubjectName.nativeElement.focus();
        }, 0);
    };
    DialogCCBCSubjectsComponent.prototype.cancelAddingNewSubject = function () {
        this.isAddingNewSubject = false;
        this.newSubjectName.reset();
    };
    // Custom validator to check if the given CCBC Subject name is already in-use
    DialogCCBCSubjectsComponent.prototype.subjectExistsValidator = function (subjectId) {
        var _this = this;
        return function (control) {
            if (control.value === null) {
                return null;
            }
            // Default to zero, for a new CCBC Subject
            var ccbcSubjectId = 0;
            // If we were given a CCBCSubjectId
            if (subjectId) {
                ccbcSubjectId = subjectId;
            }
            // If we found a different CCBC Subject that has the same name (value of the control)
            if (_this.subjectExists(ccbcSubjectId, control.value.toLowerCase().trim())) {
                return { subjectExists: { value: control.value } };
            }
            return null;
        };
    };
    // Check our list of subjects to see if the given name is in-use by a different subject
    DialogCCBCSubjectsComponent.prototype.subjectExists = function (ccbcSubjectId, subjectName) {
        var e_2, _a;
        try {
            for (var _b = tslib_1.__values(this.allSubjects), _c = _b.next(); !_c.done; _c = _b.next()) {
                var subject = _c.value;
                // If there's a subject with the same name and different ID
                if (subject.name.toLowerCase().trim() === subjectName.toLowerCase().trim() && ccbcSubjectId !== subject.ccbcSubjectId) {
                    return true;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return false;
    };
    DialogCCBCSubjectsComponent.prototype.makeRowEditable = function (ccbcSubjectFormGroup) {
        var _this = this;
        ccbcSubjectFormGroup.controls.editing.setValue(true);
        // Wait for the form field to be rendered, then focus on it
        setTimeout(function () {
            var inputToFocus = _this.el.nativeElement.querySelector('[name="editSubjectName' + ccbcSubjectFormGroup.controls.id.value + '"]');
            inputToFocus.focus();
        }, 0);
    };
    DialogCCBCSubjectsComponent.prototype.cancelEditRow = function (ccbcSubjectFormGroup) {
        // Revert to the default name, and toggle the editing flag
        ccbcSubjectFormGroup.controls.name.reset(ccbcSubjectFormGroup.controls.originalName.value);
        ccbcSubjectFormGroup.controls.editing.setValue(false);
    };
    DialogCCBCSubjectsComponent.prototype.saveEditRow = function (ccbcSubjectFormGroup) {
        var _this = this;
        // Since multiple rows can be in the editable state at the same time,
        // someone could start editing two rows, specify a new identical subject name for both rows
        // and then click save on each row. We need to manually trigger the validation on this row before saving
        ccbcSubjectFormGroup.controls.name.updateValueAndValidity();
        if (!ccbcSubjectFormGroup.valid) {
            return;
        }
        var newSubject = new CCBCSubject();
        newSubject.ccbcSubjectId = ccbcSubjectFormGroup.controls.id.value;
        newSubject.name = ccbcSubjectFormGroup.controls.name.value;
        this.crudCallIsPending = true;
        // Update subject via service
        this.bookService.updateCCBCSubject(newSubject)
            .subscribe(function (updatedSubject) {
            // Update the subject in our global array
            var subject = _this.allSubjects.find(function (s) { return s.ccbcSubjectId === updatedSubject.ccbcSubjectId; });
            subject.name = newSubject.name;
            // We're no longer editing
            ccbcSubjectFormGroup.controls.editing.setValue(false);
            ccbcSubjectFormGroup.controls.originalName.setValue(newSubject.name);
            _this.crudCallIsPending = false;
        }, function (error) {
            // We're expecting error to be an instance of HttpErrorResponse
            console.log(error);
            _this.crudCallIsPending = false;
            // This is most likely the frontend submitting invalid data,
            // the backend API logs should be checked for further info
            if (error.status !== undefined && error.status === 400) {
                window.alert('Error saving edits to CCBC Subject - Bad Request');
                return;
            }
            window.alert('Unknown error saving edits to CCBC Subject');
        });
    };
    DialogCCBCSubjectsComponent.prototype.saveNew = function () {
        var _this = this;
        var newSubject = new CCBCSubject();
        newSubject.ccbcSubjectId = 0;
        newSubject.name = this.newSubjectName.value;
        this.crudCallIsPending = true;
        // Save new subject via service
        this.bookService.addCCBCSubject(newSubject)
            .subscribe(function (createdSubject) {
            // Add the subject to our global array
            _this.allSubjects.push(createdSubject);
            // Append to our editable form group
            var subjectGroup = new FormGroup({
                id: new FormControl(createdSubject.ccbcSubjectId),
                name: new FormControl(createdSubject.name, [_this.subjectExistsValidator(createdSubject.ccbcSubjectId)]),
                editing: new FormControl(false),
                originalName: new FormControl(createdSubject.name)
            });
            _this.formGroupSubjectNames.controls.subjects.insert(0, subjectGroup);
            _this.cancelAddingNewSubject();
            _this.crudCallIsPending = false;
        }, function (error) {
            // We're expecting error to be an instance of HttpErrorResponse
            console.log(error);
            _this.crudCallIsPending = false;
            // This is most likely the frontend submitting invalid data,
            // the backend API logs should be checked for further info
            if (error.status !== undefined && error.status === 400) {
                window.alert('Error saving new CCBC Subject - Bad Request');
                return;
            }
            window.alert('Unknown error saving new CCBC Subject');
        });
    };
    DialogCCBCSubjectsComponent.prototype.confirmDeleteRow = function (ccbcSubjectFormGroup) {
        var _this = this;
        var deleteDialogRef = this.confirmDeleteDialog.open(DialogConfirmDeleteCCBCSubject, {
            data: {
                id: ccbcSubjectFormGroup.controls.id.value,
                name: ccbcSubjectFormGroup.controls.originalName.value
            }
        });
        var deleteSubject = new CCBCSubject();
        deleteSubject.ccbcSubjectId = ccbcSubjectFormGroup.controls.id.value;
        deleteSubject.name = ccbcSubjectFormGroup.controls.name.value;
        deleteDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.deleteSubject(deleteSubject);
            }
        });
    };
    /**
     * Permanently delete the given CCBCSubject entry with the given id
     */
    DialogCCBCSubjectsComponent.prototype.deleteSubject = function (ccbcSubject) {
        var _this = this;
        this.crudCallIsPending = true;
        this.bookService.deleteCCBCSubject(ccbcSubject).subscribe(function (result) {
            // Remove the subject from our global array
            _this.allSubjects = _this.allSubjects.filter(function (subject) { return subject.ccbcSubjectId !== ccbcSubject.ccbcSubjectId; });
            // Remove from the FormArray
            var indexToRemove = _this.formGroupSubjectNames.value.subjects.findIndex(function (s) { return s.id === ccbcSubject.ccbcSubjectId; });
            _this.formGroupSubjectNames.controls.subjects.removeAt(indexToRemove);
            _this.crudCallIsPending = false;
        }, function (error) {
            // We're expecting error to be an instance of HttpErrorResponse
            console.log(error);
            _this.crudCallIsPending = false;
            // This is most likely the frontend submitting invalid data,
            // the backend API logs should be checked for further info
            if (error.status !== undefined && error.status === 400) {
                window.alert('Error deleting CCBC Subject - Bad Request');
                return;
            }
            window.alert('Unknown error deleting CCBC Subject');
        });
    };
    // When the close button is clicked
    DialogCCBCSubjectsComponent.prototype.closeButtonClick = function () {
        this.closeIfWeCan();
    };
    // Close the dialog if it's safe to do so
    DialogCCBCSubjectsComponent.prototype.closeIfWeCan = function () {
        // We're waiting for a backend response
        if (this.crudCallIsPending) {
            window.alert('Waiting for CCBC Subject changes to save. Please wait a few seconds and try to close this popup again.');
            return;
        }
        // Close the dialog, return all the CCBC subjects
        this.dialogRef.close({ allSubjects: this.allSubjects });
    };
    return DialogCCBCSubjectsComponent;
}());
export { DialogCCBCSubjectsComponent };
