import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { DialogCCBCSubjectsComponent } from 'src/app/components/dialog-ccbcsubjects/dialog-ccbcsubjects.component';
var BookRecommendationComponent = /** @class */ (function () {
    function BookRecommendationComponent(bookService, dialog, sanitizer) {
        this.bookService = bookService;
        this.dialog = dialog;
        this.sanitizer = sanitizer;
        //book cover upload
        this.coverWrongFileType = false;
        this.coverImageTooBig = false;
        this.imgUrl = null;
        // ----------------- AgeGradeRanges - checkboxes
        this.allAgeGradeRanges = new Array();
        this.selectedAgeGradeRanges = new Array();
        // ----------------- CCBCSubjects - multiselect with add Subjects button
        this.allCCBCSubjects = new Array();
        this.ccbcSubjectOptions = new Array();
        this.selectedCCBCSubjects = new Array();
        this.selectedCCBCSubjectsToSave = new Array();
        // ----------------- ContentTypes - checkboxes
        this.allContentTypes = new Array();
        this.selectedContentTypes = new Array();
        // ----------------- Formats - multiselect
        this.allFormats = new Array();
        this.formatOptions = new Array();
        this.selectedFormats = new Array();
        this.selectedFormatsToSave = new Array();
        // ----------------- WisconsinInterests - multiselect
        this.allWisconsinInterests = new Array();
        this.wisconsinInterestOptions = new Array();
        this.selectedWisconsinInterests = new Array();
        this.selectedWisconsinInterestsToSave = new Array();
        //////////////
        // Tiny MCE //
        //////////////
        this.isTinyMCEAlive = false;
        this.tinyMCEInput = '';
        this.tinyMCELimit = 2000;
        this.tinyMCEHasError = false;
        this.TINY_MCE_REGEX = /&nbsp;/;
        this.isRichTextValid = new EventEmitter();
    }
    BookRecommendationComponent.prototype.ngOnInit = function () {
        this.model.newBookCover = null;
        this.getAgeGradeRanges();
        this.getAllCCBCSubjects();
        // Transforms Array<RecommendationsCCBCSubject> into an array of 
        // generic data objects for the multi-select component, and appends them to this.selectedCCBCSubjects
        this.transformRecommendationCCBCSubject(this.model.recommendationsCCBCSubjects);
        this.getContentTypes();
        this.getAllFormats();
        this.transformRecommendationFormat(this.model.recommendationsFormats);
        this.getAllWisconsinInterests();
        this.transformRecommendationWisconsinInterest(this.model.recommendationsWisconsinInterests);
    };
    Object.defineProperty(BookRecommendationComponent.prototype, "coverUrl", {
        //Book Cover display
        get: function () {
            var temp = null;
            if (this.model.coverImage) {
                // If we know the correct content type, use it, else default to png
                var tempCoverImageContentType = this.model.coverImageContentType ? this.model.coverImageContentType : 'image/png';
                temp = this.sanitizer.bypassSecurityTrustUrl('data:' + tempCoverImageContentType + ';base64,' + this.model.coverImage);
            }
            return temp;
        },
        enumerable: true,
        configurable: true
    });
    ;
    // Handle keyboard event triggering the select file "button"
    BookRecommendationComponent.prototype.openFileBrowser = function (event) {
        var target = event.target;
        target.click();
    };
    BookRecommendationComponent.prototype.handleFileInput = function (files) {
        var _this = this;
        this.coverWrongFileType = false;
        this.coverImageTooBig = false;
        //validate upload
        if (files.length !== 1) {
            return;
        }
        var type = ['image/png', 'image/jpeg', 'image/jpg'];
        this.coverWrongFileType = type.findIndex(function (x) { return files[0].type === x; }) < 0;
        var maxSize = 500000;
        this.coverImageTooBig = files[0].size > maxSize;
        if (this.coverWrongFileType || this.coverImageTooBig) {
            return;
        }
        //add to recommendation
        this.model.newBookCover = files[0];
        //display img preview
        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = function (_event) {
            _this.imgUrl = reader.result;
        };
    };
    BookRecommendationComponent.prototype.getAgeGradeRanges = function () {
        var _this = this;
        this.bookService.getAllAgeGradeRangeOptions().subscribe(function (ageGradeRanges) {
            if (ageGradeRanges != undefined && ageGradeRanges.length > 0) {
                _this.populateAgeGradeRange(ageGradeRanges);
            }
        });
    };
    BookRecommendationComponent.prototype.populateAgeGradeRange = function (ageGradeRanges) {
        var _this = this;
        this.selectedAgeGradeRanges = this.model.recommendationsAgeGradeRanges;
        this.allAgeGradeRanges = ageGradeRanges;
        this.allAgeGradeRanges.forEach(function (item) {
            if (_this.selectedAgeGradeRanges.filter(function (age) { return age.ageGradeRangeId === item.ageGradeRangeId; }).length > 0) {
                item.selected = true;
            }
            else {
                item.selected = false;
            }
        });
    };
    BookRecommendationComponent.prototype.onClickAgeGradeRange = function () {
        var _this = this;
        var updatedAgeGradeRanges = new Array();
        this.allAgeGradeRanges.forEach(function (all) {
            if (all.selected) {
                var updatedAgeGradeRange = {
                    bookId: _this.model.bookId,
                    ageGradeRangeId: all.ageGradeRangeId,
                    ageGradeRange: null,
                    recommendation: null
                };
                updatedAgeGradeRanges.push(updatedAgeGradeRange);
            }
        });
        this.model.recommendationsAgeGradeRanges = updatedAgeGradeRanges;
    };
    BookRecommendationComponent.prototype.getAllCCBCSubjects = function () {
        var _this = this;
        this.bookService.getAllCCBCSubjectOptions().subscribe(function (subjects) {
            if (subjects !== undefined && subjects.length > 0) {
                _this.allCCBCSubjects = subjects;
                // Convert to multi-select options and set the multi-select input param
                _this.assembleCCBCSubjectOptions(subjects);
            }
        });
    };
    // Convert an array of CCBCSubject objects to IMultiSelectOptions instances,
    // and set this.ccbcSubjectOptions - which is passed as an input param to the multi-select component 
    BookRecommendationComponent.prototype.assembleCCBCSubjectOptions = function (subjects) {
        var e_1, _a;
        var tempCCBCSubjectOptions = new Array();
        try {
            for (var subjects_1 = tslib_1.__values(subjects), subjects_1_1 = subjects_1.next(); !subjects_1_1.done; subjects_1_1 = subjects_1.next()) {
                var subject = subjects_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: subject.ccbcSubjectId,
                    displayText: subject.name
                };
                tempCCBCSubjectOptions.push(data);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (subjects_1_1 && !subjects_1_1.done && (_a = subjects_1.return)) _a.call(subjects_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        //this.ccbcSubjectOptions = JSON.parse(JSON.stringify(tempCCBCSubjectOptions));
        this.ccbcSubjectOptions = tempCCBCSubjectOptions;
    };
    // Transforms an array of RecommendationsCCBCSubject objects into an array of 
    // generic data objects for the multi-select component, and appends them to this.selectedCCBCSubjects
    BookRecommendationComponent.prototype.transformRecommendationCCBCSubject = function (recommendationCCBCSubjects) {
        var _this = this;
        recommendationCCBCSubjects.forEach(function (item) {
            var data = {
                option: item.ccbcSubjectId,
                displayText: item.ccbcSubject.name
            };
            _this.selectedCCBCSubjects.push(data);
        });
    };
    BookRecommendationComponent.prototype.setSelectedCCBCSubject = function (subjects) {
        this.selectedCCBCSubjects = subjects;
        this.selectedCCBCSubjects = this.selectedCCBCSubjects.slice();
        this.updateRecommendationCCBCSubjectToSave();
    };
    BookRecommendationComponent.prototype.updateRecommendationCCBCSubjectToSave = function () {
        var _this = this;
        // Transform multi-select data 
        var updatedRecommendationCCBCSubjects = new Array();
        this.selectedCCBCSubjects.forEach(function (item) {
            var recommendationCCBCSubject = {
                bookId: _this.model.bookId,
                ccbcSubjectId: item.option,
                ccbcSubject: null,
                recommendation: null
            };
            updatedRecommendationCCBCSubjects.push(recommendationCCBCSubject);
        });
        this.model.recommendationsCCBCSubjects = updatedRecommendationCCBCSubjects;
    };
    BookRecommendationComponent.prototype.getContentTypes = function () {
        var _this = this;
        this.bookService.getAllContentTypeOptions().subscribe(function (contentTypes) {
            if (contentTypes != undefined && contentTypes.length > 0) {
                _this.populateContentTypes(contentTypes);
            }
        });
    };
    BookRecommendationComponent.prototype.populateContentTypes = function (contentTypes) {
        var _this = this;
        this.selectedContentTypes = this.model.recommendationsContentTypes;
        this.allContentTypes = contentTypes;
        this.allContentTypes.forEach(function (item) {
            if (_this.selectedContentTypes.filter(function (ct) { return ct.contentTypeId === item.contentTypeId; }).length > 0) {
                item.selected = true;
            }
            else {
                item.selected = false;
            }
        });
    };
    BookRecommendationComponent.prototype.onClickContentType = function () {
        var _this = this;
        var updatedContentTypes = new Array();
        this.allContentTypes.forEach(function (all) {
            if (all.selected) {
                var updatedContentType = {
                    bookId: _this.model.bookId,
                    contentTypeId: all.contentTypeId,
                    contentType: null,
                    recommendation: null
                };
                updatedContentTypes.push(updatedContentType);
            }
        });
        this.model.recommendationsContentTypes = updatedContentTypes;
    };
    BookRecommendationComponent.prototype.getAllFormats = function () {
        var _this = this;
        this.bookService.getAllFormatOptions().subscribe(function (formats) {
            if (formats != undefined && formats.length > 0) {
                _this.allFormats = formats;
                _this.assembleFormatOptions(formats);
            }
        });
    };
    BookRecommendationComponent.prototype.assembleFormatOptions = function (formats) {
        var e_2, _a;
        try {
            for (var formats_1 = tslib_1.__values(formats), formats_1_1 = formats_1.next(); !formats_1_1.done; formats_1_1 = formats_1.next()) {
                var format = formats_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: format.formatId,
                    displayText: format.name
                };
                this.formatOptions.push(data);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (formats_1_1 && !formats_1_1.done && (_a = formats_1.return)) _a.call(formats_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        this.formatOptions = this.formatOptions.slice();
    };
    BookRecommendationComponent.prototype.transformRecommendationFormat = function (recommendationFormats) {
        var _this = this;
        recommendationFormats.forEach(function (item) {
            var data = {
                option: item.formatId,
                displayText: item.format.name
            };
            _this.selectedFormats.push(data);
        });
    };
    BookRecommendationComponent.prototype.setSelectedFormat = function (formats) {
        this.selectedFormats = formats;
        this.selectedFormats = this.selectedFormats.slice();
        this.updateRecommendationFormatToSave();
    };
    BookRecommendationComponent.prototype.updateRecommendationFormatToSave = function () {
        var _this = this;
        var updatedFormats = new Array();
        this.selectedFormats.forEach(function (item) {
            var recoomendationFormat = {
                bookId: _this.model.bookId,
                formatId: item.option,
                format: null,
                recommendation: null
            };
            updatedFormats.push(recoomendationFormat);
        });
        this.model.recommendationsFormats = updatedFormats;
    };
    BookRecommendationComponent.prototype.getAllWisconsinInterests = function () {
        var _this = this;
        this.bookService.getAllWisconsinInterestOptions().subscribe(function (interests) {
            if (interests != undefined && interests.length > 0) {
                _this.allWisconsinInterests = interests;
                _this.assembleWisconsinInterestOptions(interests);
            }
        });
    };
    BookRecommendationComponent.prototype.assembleWisconsinInterestOptions = function (interests) {
        var e_3, _a;
        try {
            for (var interests_1 = tslib_1.__values(interests), interests_1_1 = interests_1.next(); !interests_1_1.done; interests_1_1 = interests_1.next()) {
                var interest = interests_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: interest.id,
                    displayText: interest.name
                };
                this.wisconsinInterestOptions.push(data);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (interests_1_1 && !interests_1_1.done && (_a = interests_1.return)) _a.call(interests_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        this.wisconsinInterestOptions = this.wisconsinInterestOptions.slice();
    };
    BookRecommendationComponent.prototype.transformRecommendationWisconsinInterest = function (recommendationWisconsinInterests) {
        var _this = this;
        recommendationWisconsinInterests.forEach(function (item) {
            var data = {
                option: item.wisconsinInterestId,
                displayText: item.wisconsinInterest.name
            };
            _this.selectedWisconsinInterests.push(data);
        });
    };
    BookRecommendationComponent.prototype.setSelectedWisconsinInterest = function (interests) {
        this.selectedWisconsinInterests = interests;
        this.selectedWisconsinInterests = this.selectedWisconsinInterests.slice();
        this.updateRecommendationWisconsinInterestToSave();
    };
    BookRecommendationComponent.prototype.updateRecommendationWisconsinInterestToSave = function () {
        var _this = this;
        var updatedWisconsinInterests = new Array();
        this.selectedWisconsinInterests.forEach(function (item) {
            var recommendationWisconsinInterest = {
                bookId: _this.model.bookId,
                wisconsinInterestId: item.option,
                wisconsinInterest: null,
                recommendation: null
            };
            updatedWisconsinInterests.push(recommendationWisconsinInterest);
        });
        this.model.recommendationsWisconsinInterests = updatedWisconsinInterests;
    };
    /**
     * Displays a modal where users can create, edit, and delete subjects
     */
    BookRecommendationComponent.prototype.showManageSubjectsModal = function () {
        var _this = this;
        // Create new dialog with data that matches IPublisherData
        var dialogRef = this.dialog.open(DialogCCBCSubjectsComponent, {
            width: '80%',
            data: {
                allCCBCSubjects: this.allCCBCSubjects
            }
        });
        // When the modal dialog closes
        dialogRef.afterClosed().subscribe(function (result) {
            // If the dialog component didn't return an array of all subjects
            if (result === undefined || result.allSubjects === undefined) {
                // We should never get here unless there's a bug with the dialog
                // Refetch all the subjects from the backend
                // and update this.allCCBCSubjects and this.ccbcSubjectOptions
                _this.getAllCCBCSubjects();
            }
            else {
                // Update the list of all CCBC Subjects for this component
                _this.allCCBCSubjects = result.allSubjects;
            }
            // update this.ccbcSubjectOptions for the multi-select component
            _this.assembleCCBCSubjectOptions(_this.allCCBCSubjects);
        });
    };
    /**
     * Set initial input value equal to back end data
     */
    BookRecommendationComponent.prototype.initTinyMCE = function () {
        if (this.isTinyMCEAlive) {
            return;
        }
        if (this.model.ccbcChoicesAnnotation != null) {
            this.tinyMCEInput = this.model.ccbcChoicesAnnotation;
        }
        this.tinyMCEHasError = this.checkTinyMCEHasError();
        this.isTinyMCEAlive = true;
    };
    /**
     * Get length of editor input after stripping non-breaking spaces. We should not get any of these
     * if input validation works, but we should check for them just in case.
     */
    BookRecommendationComponent.prototype.getTinyMCEInputLength = function () {
        var length = this.tinyMCEInput.replace(this.TINY_MCE_REGEX, ' ').trim().length;
        return length;
    };
    /**
     * Check if input length is within limit
     */
    BookRecommendationComponent.prototype.checkTinyMCEHasError = function () {
        var length = this.getTinyMCEInputLength();
        return length > this.tinyMCELimit;
    };
    /**
     * Check for length within constraints and update model data to match editor input
     * (minus auto-inserted non-breaking spaces to save on character limit)
     */
    BookRecommendationComponent.prototype.onTinyMCEInput = function () {
        // Store rich text but replace '&nbsp;' to save on characters
        this.model.ccbcChoicesAnnotation = this.tinyMCEInput.replace(this.TINY_MCE_REGEX, ' ').trim();
        // Update UI helper and bubble up event to disable saving the book
        this.tinyMCEHasError = this.checkTinyMCEHasError();
        this.isRichTextValid.emit(this.tinyMCEHasError);
    };
    return BookRecommendationComponent;
}());
export { BookRecommendationComponent };
