import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
var DialogBibliographyBookComponent = /** @class */ (function () {
    function DialogBibliographyBookComponent(dialogRef, data, bibliographyService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.bibliographyService = bibliographyService;
        this.searchInitiated = false;
    }
    DialogBibliographyBookComponent.prototype.ngOnInit = function () {
        this.bookList = new Array();
    };
    DialogBibliographyBookComponent.prototype.getBooks = function () {
        var _this = this;
        this.searchInitiated = true;
        this.bibliographyService.searchForBooks(this.searchText).subscribe(function (bl) {
            _this.bookList = bl;
        });
    };
    /**
     * Trigger the dialog's close event in response to user action.
     */
    DialogBibliographyBookComponent.prototype.closeDialog = function () {
        var e_1, _a;
        var addBookList = new Array();
        var _loop_1 = function (book) {
            //don't allow duplicate books to be added to the category
            var bookExists = this_1.data.category.booksForBibliographyView.some(function (elem) { return elem.bookId == book.bookId; });
            if (book.add == true && bookExists == false) {
                addBookList.push(book);
            }
        };
        var this_1 = this;
        try {
            for (var _b = tslib_1.__values(this.bookList), _c = _b.next(); !_c.done; _c = _b.next()) {
                var book = _c.value;
                _loop_1(book);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.dialogRef.close(addBookList);
    };
    DialogBibliographyBookComponent.prototype.cancelAdd = function () {
        this.dialogRef.close(-1);
    };
    return DialogBibliographyBookComponent;
}());
export { DialogBibliographyBookComponent };
