import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
// Classes
import { ContributorPersonalInfo, ContributorsGenders, ContributorsReligions, ContributorsContributorHeritages, ContributorsDisabilities } from 'src/app/classes/Contributor';
/**
 * Base component that contains functionality shared by the add/edit contributor PAGE and MODAL. It is extended by
 * DialogNewContributorComponent and PageNewContributorComponent, which follow it in this file.
 */
var AddEditContributorComponent = /** @class */ (function () {
    function AddEditContributorComponent(contributorService, booksService, titleService) {
        this.contributorService = contributorService;
        this.booksService = booksService;
        this.titleService = titleService;
        this.isNewContributor = true;
        this.isCanceled = false;
        this.contributor = new ContributorPersonalInfo();
        this.contributorBooks = new Array();
        this.allGenders = new Array();
        this.genderOptions = new Array();
        this.selectedGenders = new Array();
        this.allReligions = new Array();
        this.religionOptions = new Array();
        this.selectedReligions = new Array();
        this.allHeritages = new Array();
        this.heritageOptions = new Array();
        this.selectedHeritages = new Array();
        this.allDisabilities = new Array();
        this.disabilityOptions = new Array();
        this.selectedDisabilities = new Array();
    }
    AddEditContributorComponent.prototype.init = function () {
        this.getAllGenders();
        this.getAllReligions();
        this.getAllHeritages();
        this.getAllDisabilities();
    };
    AddEditContributorComponent.prototype.setTitle = function (title) {
        this.titleService.setTitle(title);
    };
    /**
     * Get all books associated with the given contributor id
     * @param {number} id "contributorId" of a ContributorPersonalInfo class
     */
    AddEditContributorComponent.prototype.getBooksForContributor = function (id) {
        var _this = this;
        this.booksService.getBooksForContributorId(id).subscribe(function (result) {
            if (result != undefined && result.length > 0) {
                _this.contributorBooks = result;
            }
        });
    };
    /**
     * Get available contributor gender entries
     */
    AddEditContributorComponent.prototype.getAllGenders = function () {
        var _this = this;
        this.contributorService.getContributorGenders().subscribe(function (genders) {
            var e_1, _a;
            if (genders != undefined && genders.length > 0) {
                _this.allGenders = genders;
                _this.assembleGenderOptions(genders);
            }
            if (_this.contributor.contributorsGenders.length > 0) {
                var _loop_1 = function (gender) {
                    var temp = _this.allGenders.find(function (entry) { return entry.id === gender.genderId; });
                    var data = {
                        option: temp.id,
                        displayText: temp.name
                    };
                    _this.selectedGenders.push(data);
                };
                try {
                    for (var _b = tslib_1.__values(_this.contributor.contributorsGenders), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var gender = _c.value;
                        _loop_1(gender);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                _this.selectedGenders = _this.selectedGenders.slice();
            }
        });
    };
    /**
   * Get available contributor religion entries
   */
    AddEditContributorComponent.prototype.getAllReligions = function () {
        var _this = this;
        this.booksService.getAllReligions().subscribe(function (religions) {
            var e_2, _a;
            if (religions != undefined && religions.length > 0) {
                _this.allReligions = religions;
                _this.assembleReligionOptions(religions);
            }
            if (_this.contributor.contributorsReligions.length > 0) {
                var _loop_2 = function (religion) {
                    var temp = _this.allReligions.find(function (entry) { return entry.id === religion.religionId; });
                    var data = {
                        option: temp.id,
                        displayText: temp.name
                    };
                    _this.selectedReligions.push(data);
                };
                try {
                    for (var _b = tslib_1.__values(_this.contributor.contributorsReligions), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var religion = _c.value;
                        _loop_2(religion);
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                _this.selectedReligions = _this.selectedReligions.slice();
            }
        });
    };
    /**
     * Get available contributor heritage entries
     */
    AddEditContributorComponent.prototype.getAllHeritages = function () {
        var _this = this;
        this.contributorService.getContributorHeritages().subscribe(function (heritages) {
            var e_3, _a;
            if (heritages != undefined && heritages.length > 0) {
                _this.allHeritages = heritages;
                _this.assembleHeritageOptions(heritages);
            }
            if (_this.contributor.contributorsContributorHeritages.length > 0) {
                var _loop_3 = function (heritage) {
                    var temp = _this.allHeritages.find(function (entry) { return entry.id === heritage.contributorHeritageId; });
                    var data = {
                        option: temp.id,
                        displayText: temp.name
                    };
                    _this.selectedHeritages.push(data);
                };
                try {
                    for (var _b = tslib_1.__values(_this.contributor.contributorsContributorHeritages), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var heritage = _c.value;
                        _loop_3(heritage);
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
                _this.selectedHeritages = _this.selectedHeritages.slice();
            }
        });
    };
    /**
     * Get available contributor disability entries
     */
    AddEditContributorComponent.prototype.getAllDisabilities = function () {
        var _this = this;
        this.booksService.getAllDisabilities().subscribe(function (disabilities) {
            var e_4, _a;
            if (disabilities != undefined && disabilities.length > 0) {
                _this.allDisabilities = disabilities;
                _this.assembleDisabilityOptions(disabilities);
            }
            if (_this.contributor.contributorsDisabilities.length > 0) {
                var _loop_4 = function (disability) {
                    var temp = _this.allDisabilities.find(function (entry) { return entry.id === disability.disabilityId; });
                    var data = {
                        option: temp.id,
                        displayText: temp.name
                    };
                    _this.selectedDisabilities.push(data);
                    _this.selectedDisabilities = _this.selectedDisabilities.slice();
                };
                try {
                    for (var _b = tslib_1.__values(_this.contributor.contributorsDisabilities), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var disability = _c.value;
                        _loop_4(disability);
                    }
                }
                catch (e_4_1) { e_4 = { error: e_4_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_4) throw e_4.error; }
                }
            }
        });
    };
    /**
     * Transform gender data into options for the multi-select component
     * @param {Array<Gender>} genders Array of Gender objects
     */
    AddEditContributorComponent.prototype.assembleGenderOptions = function (genders) {
        var e_5, _a;
        try {
            for (var genders_1 = tslib_1.__values(genders), genders_1_1 = genders_1.next(); !genders_1_1.done; genders_1_1 = genders_1.next()) {
                var gender = genders_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: gender.id,
                    displayText: gender.name
                };
                this.genderOptions.push(data);
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (genders_1_1 && !genders_1_1.done && (_a = genders_1.return)) _a.call(genders_1);
            }
            finally { if (e_5) throw e_5.error; }
        }
        this.genderOptions = this.genderOptions.slice();
    };
    /**
   * Transform religion data into options for the multi-select component
   * @param {Array<Religion>} religions Array of Religion objects
   */
    AddEditContributorComponent.prototype.assembleReligionOptions = function (religions) {
        var e_6, _a;
        try {
            for (var religions_1 = tslib_1.__values(religions), religions_1_1 = religions_1.next(); !religions_1_1.done; religions_1_1 = religions_1.next()) {
                var religion = religions_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: religion.id,
                    displayText: religion.name
                };
                this.religionOptions.push(data);
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (religions_1_1 && !religions_1_1.done && (_a = religions_1.return)) _a.call(religions_1);
            }
            finally { if (e_6) throw e_6.error; }
        }
        this.religionOptions = this.religionOptions.slice();
    };
    /**
     * Transform heritage data into options for the multi-select component
     * @param {Array<ContributorHeritage>} heritages Array of contributor heritage objects
     */
    AddEditContributorComponent.prototype.assembleHeritageOptions = function (heritages) {
        var e_7, _a;
        try {
            for (var heritages_1 = tslib_1.__values(heritages), heritages_1_1 = heritages_1.next(); !heritages_1_1.done; heritages_1_1 = heritages_1.next()) {
                var heritage = heritages_1_1.value;
                var data = {
                    option: heritage.id,
                    displayText: heritage.name
                };
                this.heritageOptions.push(data);
            }
        }
        catch (e_7_1) { e_7 = { error: e_7_1 }; }
        finally {
            try {
                if (heritages_1_1 && !heritages_1_1.done && (_a = heritages_1.return)) _a.call(heritages_1);
            }
            finally { if (e_7) throw e_7.error; }
        }
        this.heritageOptions = this.heritageOptions.slice();
    };
    /**
     * Transform disability data into options for the multi-select component
     * @param {Array<ContributorDisability>} disabilities Array of contributor disability objects
     */
    AddEditContributorComponent.prototype.assembleDisabilityOptions = function (disabilities) {
        var e_8, _a;
        try {
            for (var disabilities_1 = tslib_1.__values(disabilities), disabilities_1_1 = disabilities_1.next(); !disabilities_1_1.done; disabilities_1_1 = disabilities_1.next()) {
                var disability = disabilities_1_1.value;
                var data = {
                    option: disability.id,
                    displayText: disability.name
                };
                this.disabilityOptions.push(data);
            }
        }
        catch (e_8_1) { e_8 = { error: e_8_1 }; }
        finally {
            try {
                if (disabilities_1_1 && !disabilities_1_1.done && (_a = disabilities_1.return)) _a.call(disabilities_1);
            }
            finally { if (e_8) throw e_8.error; }
        }
        this.disabilityOptions = this.disabilityOptions.slice();
    };
    /**
     * Transform user's multi-select selections into data the back end will accept
     */
    AddEditContributorComponent.prototype.updateGendersToSave = function () {
        var _this = this;
        // Transform multi-select data into book contributor genders
        var updatedGenders = new Array();
        this.selectedGenders.forEach(function (item) {
            var gender = new ContributorsGenders();
            gender.contributorId = _this.contributor.id || 0;
            gender.genderId = item.option;
            updatedGenders.push(gender);
        });
        this.contributor.contributorsGenders = updatedGenders;
    };
    /**
   * Transform user's multi-select selections into data the back end will accept
   */
    AddEditContributorComponent.prototype.updateReligionsToSave = function () {
        var _this = this;
        // Transform multi-select data into book contributor religions
        var updatedReligions = new Array();
        this.selectedReligions.forEach(function (item) {
            var religion = new ContributorsReligions();
            religion.contributorId = _this.contributor.id || 0;
            religion.religionId = item.option;
            updatedReligions.push(religion);
        });
        this.contributor.contributorsReligions = updatedReligions;
    };
    /**
     * Transform user's multi-select selections into data the back end will accept
     */
    AddEditContributorComponent.prototype.updateHeritagesToSave = function () {
        var _this = this;
        // Transform multi-select data into contributor heritages
        var updatedHeritages = new Array();
        this.selectedHeritages.forEach(function (item) {
            var heritage = new ContributorsContributorHeritages();
            heritage.contributorId = _this.contributor.id || 0;
            heritage.contributorHeritageId = item.option;
            // heritage.contributorHeritage = this.allHeritages.find(entry => entry.id === item.option);
            updatedHeritages.push(heritage);
        });
        this.contributor.contributorsContributorHeritages = updatedHeritages;
    };
    /**
     * Transform user's multi-select selections into data the back end will accept
     */
    AddEditContributorComponent.prototype.updateDisabilitiesToSave = function () {
        var _this = this;
        // Transform multi-select data into contributor disabilities
        var updatedDisabilities = new Array();
        this.selectedDisabilities.forEach(function (item) {
            var disability = new ContributorsDisabilities();
            disability.contributorId = _this.contributor.id || 0;
            disability.disabilityId = item.option;
            // disability.contributorDisability = this.allDisabilities.find(entry => entry.id === item.option);
            updatedDisabilities.push(disability);
        });
        this.contributor.contributorsDisabilities = updatedDisabilities;
    };
    /**
     * Update user's new gender selections
     * @param {Array<IMultiSelectOptions>} genders Array of multi-select options for gender
     */
    AddEditContributorComponent.prototype.setSelectedGenders = function (genders) {
        this.selectedGenders = genders;
        this.selectedGenders = this.selectedGenders.slice();
        this.updateGendersToSave();
    };
    /**
   * Update user's new religion selections
   * @param {Array<IMultiSelectOptions>} genders Array of multi-select options for gender
   */
    AddEditContributorComponent.prototype.setSelectedReligions = function (religions) {
        this.selectedReligions = religions;
        this.selectedReligions = this.selectedReligions.slice();
        this.updateReligionsToSave();
    };
    /**
     * Update user's new heritage selections
     * @param {Array<IMultiSelectOptions>} heritages Array of multi-select options for heritage
     */
    AddEditContributorComponent.prototype.setSelectedHeritages = function (heritages) {
        this.selectedHeritages = heritages;
        this.selectedHeritages = this.selectedHeritages.slice();
        this.updateHeritagesToSave();
    };
    /**
     * Update user's new disability selections
     * @param {Array<IMultiSelectOptions>} disabilities Array of multi-select options for gender
     */
    AddEditContributorComponent.prototype.setSelectedDisabilities = function (disabilities) {
        this.selectedDisabilities = disabilities;
        this.selectedDisabilities = this.selectedDisabilities.slice();
        this.updateDisabilitiesToSave();
    };
    /////////////////////
    // EVENT LISTENERS //
    /////////////////////
    /**
     * Toggle mutually exclusive checkboxes to ensure only one value is saved. This is necessary
     * because the respective properties in the data model are two separate properties, rather than
     * a single property with a boolean value (disallowing HTML radio buttons).
     * @param {Event} event Event emitted by MatCheckbox
     */
    AddEditContributorComponent.prototype.pretendToBeRadio = function (event) {
        if (event.source.name === 'heritageContested' && event.checked) {
            this.contributor.verified = false;
        }
        else if (event.source.name === 'heritageVerified' && event.checked) {
            this.contributor.culturalHeritageContested = false;
        }
    };
    return AddEditContributorComponent;
}());
export { AddEditContributorComponent };
/**
 * Component with behaviors and template unique to the add/edit contributors PAGE.
 */
var PageNewContributorComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PageNewContributorComponent, _super);
    function PageNewContributorComponent(contributorService, booksService, titleService, router, route) {
        var _this = _super.call(this, contributorService, booksService, titleService) || this;
        _this.contributorService = contributorService;
        _this.booksService = booksService;
        _this.titleService = titleService;
        _this.router = router;
        _this.route = route;
        _this.isLoading = true;
        return _this;
    }
    PageNewContributorComponent.prototype.ngOnInit = function () {
        _super.prototype.init.call(this);
        var id = +this.route.snapshot.paramMap.get('id');
        if (id === 0) {
            this.isNewContributor = true;
            this.contributor = new ContributorPersonalInfo();
            this.isLoading = false;
            this.setTitle('New contributor | CCBC Book Search');
        }
        else {
            // Get existing contributor info
            this.isNewContributor = false;
            this.setTitle('Edit contributor | CCBC Book Search');
            this.getContributor(id);
        }
    };
    /**
     * Look up the contributor with the given id
     * @param {number} id Contributor id received via route parameters
     */
    PageNewContributorComponent.prototype.getContributor = function (id) {
        var _this = this;
        this.contributorService.getContributors().subscribe(function (contributors) {
            _this.contributor = contributors.find(function (entry) { return entry.id === id; });
            if (_this.contributor != undefined) {
                _this.getBooksForContributor(id);
                _this.updateExistingSelections();
            }
            else {
                _this.hasError = true;
                _this.isLoading = false;
                _this.errorId = _this.route.snapshot.paramMap.get('id');
                console.info("couldn't find a contributor with id: " + id);
            }
        });
    };
    /**
     * Transform contributor's existing array-type attributes into multi-select options
     * to be displayed, then stop "loading"
     */
    PageNewContributorComponent.prototype.updateExistingSelections = function () {
        var e_9, _a, e_10, _b, e_11, _c, e_12, _d;
        if (this.contributor.contributorsGenders.length > 0) {
            var _loop_5 = function (gender) {
                var temp = this_1.allGenders.find(function (entry) { return entry.id === gender.genderId; });
                var data = {
                    option: temp.id,
                    displayText: temp.name
                };
                this_1.selectedGenders.push(data);
                this_1.selectedGenders = this_1.selectedGenders.slice();
            };
            var this_1 = this;
            try {
                for (var _e = tslib_1.__values(this.contributor.contributorsGenders), _f = _e.next(); !_f.done; _f = _e.next()) {
                    var gender = _f.value;
                    _loop_5(gender);
                }
            }
            catch (e_9_1) { e_9 = { error: e_9_1 }; }
            finally {
                try {
                    if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
                }
                finally { if (e_9) throw e_9.error; }
            }
        }
        if (this.contributor.contributorsReligions.length > 0) {
            var _loop_6 = function (religion) {
                var temp = this_2.allReligions.find(function (entry) { return entry.id === religion.religionId; });
                var data = {
                    option: temp.id,
                    displayText: temp.name
                };
                this_2.selectedReligions.push(data);
                this_2.selectedReligions = this_2.selectedReligions.slice();
            };
            var this_2 = this;
            try {
                for (var _g = tslib_1.__values(this.contributor.contributorsReligions), _h = _g.next(); !_h.done; _h = _g.next()) {
                    var religion = _h.value;
                    _loop_6(religion);
                }
            }
            catch (e_10_1) { e_10 = { error: e_10_1 }; }
            finally {
                try {
                    if (_h && !_h.done && (_b = _g.return)) _b.call(_g);
                }
                finally { if (e_10) throw e_10.error; }
            }
        }
        if (this.contributor.contributorsContributorHeritages.length > 0) {
            var _loop_7 = function (heritage) {
                var temp = this_3.allHeritages.find(function (entry) { return entry.id === heritage.contributorHeritageId; });
                var data = {
                    option: temp.id,
                    displayText: temp.name
                };
                this_3.selectedHeritages.push(data);
                this_3.selectedHeritages = this_3.selectedHeritages.slice();
            };
            var this_3 = this;
            try {
                for (var _j = tslib_1.__values(this.contributor.contributorsContributorHeritages), _k = _j.next(); !_k.done; _k = _j.next()) {
                    var heritage = _k.value;
                    _loop_7(heritage);
                }
            }
            catch (e_11_1) { e_11 = { error: e_11_1 }; }
            finally {
                try {
                    if (_k && !_k.done && (_c = _j.return)) _c.call(_j);
                }
                finally { if (e_11) throw e_11.error; }
            }
        }
        if (this.contributor.contributorsDisabilities.length > 0) {
            var _loop_8 = function (disability) {
                var temp = this_4.allDisabilities.find(function (entry) { return entry.id === disability.disabilityId; });
                var data = {
                    option: temp.id,
                    displayText: temp.name
                };
                this_4.selectedDisabilities.push(data);
                this_4.selectedDisabilities = this_4.selectedDisabilities.slice();
            };
            var this_4 = this;
            try {
                for (var _l = tslib_1.__values(this.contributor.contributorsDisabilities), _m = _l.next(); !_m.done; _m = _l.next()) {
                    var disability = _m.value;
                    _loop_8(disability);
                }
            }
            catch (e_12_1) { e_12 = { error: e_12_1 }; }
            finally {
                try {
                    if (_m && !_m.done && (_d = _l.return)) _d.call(_l);
                }
                finally { if (e_12) throw e_12.error; }
            }
        }
        this.isLoading = false;
    };
    /**
     * Save user's changes
     */
    PageNewContributorComponent.prototype.saveContributor = function () {
        var _this = this;
        this.contributorService.saveContributor(this.contributor)
            .subscribe(function (contributor) {
            _this.lastSavedDate = new Date();
            _this.saveSuccessful = true;
            _this.isNewContributor = false;
            _this.contributor.id = contributor.id;
            _this.router.navigate(['/contributor/' + contributor.id]);
        });
    };
    return PageNewContributorComponent;
}(AddEditContributorComponent));
export { PageNewContributorComponent };
/**
 * Behaviors and template unique to the add/edit contributors MODAL
 */
var DialogNewContributorComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DialogNewContributorComponent, _super);
    function DialogNewContributorComponent(contributorService, booksService, titleService, dialogRef, data) {
        var _this = _super.call(this, contributorService, booksService, titleService) || this;
        _this.contributorService = contributorService;
        _this.booksService = booksService;
        _this.titleService = titleService;
        _this.dialogRef = dialogRef;
        _this.data = data;
        _this.dialogRef.backdropClick().subscribe(function () {
            _this.isCanceled = true;
        });
        _this.dialogRef.beforeClose().subscribe(function () {
            if (!_this.isCanceled) {
                _this.dialogRef.close(_this.contributor);
            }
            else {
                _this.dialogRef.close(-1);
            }
        });
        return _this;
    }
    DialogNewContributorComponent.prototype.ngOnInit = function () {
        _super.prototype.init.call(this);
        this.isNewContributor = this.data.isNewContributor;
        this.contributor = this.data.contributor;
        if (!this.isNewContributor) {
            this.getBooksForContributor(this.contributor.id);
        }
    };
    /**
     * Close the dialog to return altered data
     */
    DialogNewContributorComponent.prototype.setNewContributor = function () {
        this.dialogRef.close();
    };
    /**
     * Close the dialog with the cancellation flag
     */
    DialogNewContributorComponent.prototype.closeDialog = function () {
        this.isCanceled = true;
        this.dialogRef.close();
    };
    return DialogNewContributorComponent;
}(AddEditContributorComponent));
export { DialogNewContributorComponent };
