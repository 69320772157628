import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
var BookCharactersComponent = /** @class */ (function () {
    function BookCharactersComponent(bookService) {
        this.bookService = bookService;
        this.prefix = "";
        //diversity subject - Heritage setting
        this.allCharacterTypes = new Array();
        this.characterTypeOptions = new Array();
        this.selectedCharacterTypes = new Array();
        this.selectedCharacterTypeToSave = new Array();
        /*Character gender and gender specify */
        this.allCharacterGenders = new Array();
        this.characterGenderOptions = new Array();
        this.selectedCharacterGenders = new Array();
        this.selectedCharacterGenderToSave = new Array();
        this.allCharacterGenderSpecifies = new Array();
        this.characterGenderSpecifyOptions = new Array();
        this.selectedCharacterGenderSpecifies = new Array();
        this.selectedCharacterGenderSpecifyToSave = new Array();
        /* END of gender & gender specify*/
        /*Character heritage and heritage specify */
        this.allCharacterHeritages = new Array();
        this.characterHeritageOptions = new Array();
        this.selectedCharacterHeritages = new Array();
        this.selectedCharacterHeritageToSave = new Array();
        this.allCharacterHeritageSpecifies = new Array();
        this.characterHeritageSpecifyOptions = new Array();
        this.selectedCharacterHeritageSpecifies = new Array();
        this.selectedCharacterHeritageSpecifyToSave = new Array();
        /* END of gender & gender specify*/
        /*Religion */
        this.allReligions = new Array();
        this.religionOptions = new Array();
        this.selectedReligions = new Array();
        this.selectedReligionToSave = new Array();
        /*end religion*/
        /*Character Disability*/
        this.allCharacterDisabilities = new Array();
        this.characterDisabilityOptions = new Array();
        this.selectedCharacterDisabilities = new Array();
        this.selectedCharacterDisabilityToSave = new Array();
        /*END of Disability */
        /** Sexuality section */
        this.allCharacterSexuality = new Array();
        this.selectedCharacterSexualities = new Array();
    }
    BookCharactersComponent.prototype.getAllCharacterSexualities = function () {
        var _this = this;
        this.bookService.getAllCharacterSexualityOptions().subscribe(function (characterSexualities) {
            if (characterSexualities != undefined && characterSexualities.length > 0) {
                _this.populateCharacterSexuality(characterSexualities);
            }
        });
    };
    BookCharactersComponent.prototype.populateCharacterSexuality = function (characterSexuality) {
        var _this = this;
        this.selectedCharacterSexualities = this.model.charactersCharacterSexualities;
        this.allCharacterSexuality = characterSexuality;
        this.allCharacterSexuality.forEach(function (item) {
            if (_this.selectedCharacterSexualities.filter(function (sex) { return sex.characterSexualityId === item.characterSexualityId; }).length > 0) {
                item.selected = true;
            }
            else {
                item.selected = false;
            }
        });
    };
    BookCharactersComponent.prototype.onClickSexuality = function () {
        var _this = this;
        var updatedCharacterSexualities = new Array();
        this.allCharacterSexuality.forEach(function (all) {
            if (all.selected) {
                var updatedSexuality = {
                    characterId: _this.model.id,
                    characterSexualityId: all.characterSexualityId,
                    characterSexuality: null
                };
                updatedCharacterSexualities.push(updatedSexuality);
            }
        });
        this.model.charactersCharacterSexualities = updatedCharacterSexualities;
    };
    /** END of Sexuality Section */
    BookCharactersComponent.prototype.getAllCharacterTypes = function () {
        var _this = this;
        this.bookService.getAllCharacterTypeOptions().subscribe(function (characterTypes) {
            if (characterTypes != undefined && characterTypes.length > 0) {
                _this.allCharacterTypes = characterTypes;
                _this.assembleCharacterTypeOptions(characterTypes);
            }
        });
    };
    BookCharactersComponent.prototype.assembleCharacterTypeOptions = function (characterTypes) {
        var e_1, _a;
        try {
            for (var characterTypes_1 = tslib_1.__values(characterTypes), characterTypes_1_1 = characterTypes_1.next(); !characterTypes_1_1.done; characterTypes_1_1 = characterTypes_1.next()) {
                var characterType = characterTypes_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: characterType.characterTypeId,
                    displayText: characterType.characterTypeName
                };
                this.characterTypeOptions.push(data);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (characterTypes_1_1 && !characterTypes_1_1.done && (_a = characterTypes_1.return)) _a.call(characterTypes_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.characterTypeOptions = this.characterTypeOptions.slice();
    };
    BookCharactersComponent.prototype.transformCharacterCharacterType = function (characterCharacterTypes) {
        var _this = this;
        characterCharacterTypes.forEach(function (item) {
            var data = {
                option: item.characterTypeId,
                displayText: item.characterType.characterTypeName
            };
            _this.selectedCharacterTypes.push(data);
        });
    };
    BookCharactersComponent.prototype.setSelectedCharacterType = function (characterTypes) {
        // If the user removed all publishers, mark them all for deletion
        this.selectedCharacterTypes = characterTypes;
        this.selectedCharacterTypes = this.selectedCharacterTypes.slice();
        this.updateCharacterCharacterTypeToSave();
    };
    BookCharactersComponent.prototype.updateCharacterCharacterTypeToSave = function () {
        var _this = this;
        // Transform multi-select data into book publishers
        var updatedCharacterTypes = new Array();
        this.selectedCharacterTypes.forEach(function (item) {
            var characterCharacterType = {
                characterId: _this.model.id,
                characterTypeId: item.option,
                characterType: null
            };
            updatedCharacterTypes.push(characterCharacterType);
        });
        this.model.charactersCharacterTypes = updatedCharacterTypes;
    };
    /************************************************************************
     * Functions of gender and gender specifies
     *
     * *********************************************************************/
    BookCharactersComponent.prototype.getAllCharacterGenders = function () {
        var _this = this;
        this.bookService.getAllCharacterGenderOptions().subscribe(function (charactergenders) {
            if (charactergenders != undefined && charactergenders.length > 0) {
                _this.allCharacterGenders = charactergenders;
                _this.assembleGenderOptions(charactergenders);
            }
        });
    };
    BookCharactersComponent.prototype.assembleGenderOptions = function (genders) {
        var e_2, _a;
        try {
            for (var genders_1 = tslib_1.__values(genders), genders_1_1 = genders_1.next(); !genders_1_1.done; genders_1_1 = genders_1.next()) {
                var gender = genders_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: gender.id,
                    displayText: gender.name
                };
                this.characterGenderOptions.push(data);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (genders_1_1 && !genders_1_1.done && (_a = genders_1.return)) _a.call(genders_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
        this.characterGenderOptions = this.characterGenderOptions.slice();
    };
    BookCharactersComponent.prototype.transformCharacterCharacterGender = function (characterCharacterGenders) {
        var _this = this;
        characterCharacterGenders.forEach(function (item) {
            var data = {
                option: item.genderId,
                displayText: item.gender.name
            };
            _this.selectedCharacterGenders.push(data);
        });
    };
    BookCharactersComponent.prototype.setSelectedCharacterGenders = function (characterGenders) {
        // If the user removed all publishers, mark them all for deletion
        this.selectedCharacterGenders = characterGenders;
        this.selectedCharacterGenders = this.selectedCharacterGenders.slice();
        this.updateCharacterCharacterGenderToSave();
    };
    BookCharactersComponent.prototype.updateCharacterCharacterGenderToSave = function () {
        var _this = this;
        // Transform multi-select data into book publishers
        var updatedCharacterGenders = new Array();
        this.selectedCharacterGenders.forEach(function (item) {
            var characterCharacterGender = {
                characterId: _this.model.id,
                genderId: item.option,
                gender: null
            };
            updatedCharacterGenders.push(characterCharacterGender);
        });
        this.model.charactersCharacterGenders = updatedCharacterGenders;
    };
    BookCharactersComponent.prototype.getAllCharacterGenderSpecifies = function () {
        var _this = this;
        this.bookService.getAllCharacterGenderSpecifyOptions().subscribe(function (charactergenderSpecifies) {
            if (charactergenderSpecifies != undefined && charactergenderSpecifies.length > 0) {
                _this.allCharacterGenderSpecifies = charactergenderSpecifies;
                _this.assembleGenderSpecifyOptions(charactergenderSpecifies);
            }
        });
    };
    BookCharactersComponent.prototype.assembleGenderSpecifyOptions = function (genderSpecifies) {
        var e_3, _a;
        try {
            for (var genderSpecifies_1 = tslib_1.__values(genderSpecifies), genderSpecifies_1_1 = genderSpecifies_1.next(); !genderSpecifies_1_1.done; genderSpecifies_1_1 = genderSpecifies_1.next()) {
                var genderSpecify = genderSpecifies_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: genderSpecify.characterGenderSpecifyId,
                    displayText: genderSpecify.name
                };
                this.characterGenderSpecifyOptions.push(data);
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (genderSpecifies_1_1 && !genderSpecifies_1_1.done && (_a = genderSpecifies_1.return)) _a.call(genderSpecifies_1);
            }
            finally { if (e_3) throw e_3.error; }
        }
        this.characterGenderSpecifyOptions = this.characterGenderSpecifyOptions.slice();
    };
    BookCharactersComponent.prototype.transformCharacterCharacterGenderSpecify = function (characterCharacterGenderSpecifies) {
        var _this = this;
        characterCharacterGenderSpecifies.forEach(function (item) {
            var data = {
                option: item.characterGenderSpecifyId,
                displayText: item.characterGenderSpecify.name
            };
            _this.selectedCharacterGenderSpecifies.push(data);
        });
    };
    BookCharactersComponent.prototype.setSelectedCharacterGenderSpecifies = function (characterGenderSpecifies) {
        // If the user removed all publishers, mark them all for deletion
        this.selectedCharacterGenderSpecifies = characterGenderSpecifies;
        this.selectedCharacterGenderSpecifies = this.selectedCharacterGenderSpecifies.slice();
        this.updateCharacterCharacterGenderSpecifyToSave();
    };
    BookCharactersComponent.prototype.updateCharacterCharacterGenderSpecifyToSave = function () {
        var _this = this;
        // Transform multi-select data into book publishers
        var updatedCharacterGenderSpecifies = new Array();
        this.selectedCharacterGenderSpecifies.forEach(function (item) {
            var characterGenderSpecify = {
                characterId: _this.model.id,
                characterGenderSpecifyId: item.option,
                characterGenderSpecify: null
            };
            updatedCharacterGenderSpecifies.push(characterGenderSpecify);
        });
        this.model.charactersCharacterGenderSpecifies = updatedCharacterGenderSpecifies;
    };
    /**End of Functions of gender & gender specifies */
    /************************************************************************
     * Functions of Heritage and heritage specifies
     *
     * *********************************************************************/
    BookCharactersComponent.prototype.getAllCharacterHeritages = function () {
        var _this = this;
        this.bookService.getAllCharacterHeritageOptions().subscribe(function (characterHeritages) {
            if (characterHeritages != undefined && characterHeritages.length > 0) {
                _this.allCharacterHeritages = characterHeritages;
                _this.assembleHeritageOptions(characterHeritages);
            }
        });
    };
    BookCharactersComponent.prototype.assembleHeritageOptions = function (heritages) {
        var e_4, _a;
        try {
            for (var heritages_1 = tslib_1.__values(heritages), heritages_1_1 = heritages_1.next(); !heritages_1_1.done; heritages_1_1 = heritages_1.next()) {
                var heritage = heritages_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: heritage.id,
                    displayText: heritage.name
                };
                this.characterHeritageOptions.push(data);
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (heritages_1_1 && !heritages_1_1.done && (_a = heritages_1.return)) _a.call(heritages_1);
            }
            finally { if (e_4) throw e_4.error; }
        }
        this.characterHeritageOptions = this.characterHeritageOptions.slice();
    };
    BookCharactersComponent.prototype.transformCharacterCharacterHeritage = function (characterCharacterheritages) {
        var _this = this;
        characterCharacterheritages.forEach(function (item) {
            var data = {
                option: item.characterHeritageId,
                displayText: item.characterHeritage.name
            };
            _this.selectedCharacterHeritages.push(data);
        });
    };
    BookCharactersComponent.prototype.setSelectedCharacterHeritage = function (characterHeritages) {
        // If the user removed all publishers, mark them all for deletion
        this.selectedCharacterHeritages = characterHeritages;
        this.selectedCharacterHeritages = this.selectedCharacterHeritages.slice();
        this.updateCharacterCharacterHeritageToSave();
    };
    BookCharactersComponent.prototype.updateCharacterCharacterHeritageToSave = function () {
        var _this = this;
        // Transform multi-select data into book publishers
        var updatedCharacterHeritages = new Array();
        this.selectedCharacterHeritages.forEach(function (item) {
            var characterCharacterHeritage = {
                characterId: _this.model.id,
                characterHeritageId: item.option,
                characterHeritage: null
            };
            updatedCharacterHeritages.push(characterCharacterHeritage);
        });
        this.model.charactersCharacterHeritages = updatedCharacterHeritages;
    };
    BookCharactersComponent.prototype.getAllCharacterHeritageSpecifies = function () {
        var _this = this;
        this.bookService.getAllCharacterHeritageSpecifyOptions().subscribe(function (characterHeritageSpecifies) {
            if (characterHeritageSpecifies != undefined && characterHeritageSpecifies.length > 0) {
                _this.allCharacterHeritageSpecifies = characterHeritageSpecifies;
                _this.assembleHeritageSpecifyOptions(characterHeritageSpecifies);
            }
        });
    };
    BookCharactersComponent.prototype.assembleHeritageSpecifyOptions = function (heritageSpecifies) {
        var e_5, _a;
        try {
            for (var heritageSpecifies_1 = tslib_1.__values(heritageSpecifies), heritageSpecifies_1_1 = heritageSpecifies_1.next(); !heritageSpecifies_1_1.done; heritageSpecifies_1_1 = heritageSpecifies_1.next()) {
                var heritageSpecify = heritageSpecifies_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: heritageSpecify.characterHeritageSpecifyId,
                    displayText: heritageSpecify.name
                };
                this.characterHeritageSpecifyOptions.push(data);
            }
        }
        catch (e_5_1) { e_5 = { error: e_5_1 }; }
        finally {
            try {
                if (heritageSpecifies_1_1 && !heritageSpecifies_1_1.done && (_a = heritageSpecifies_1.return)) _a.call(heritageSpecifies_1);
            }
            finally { if (e_5) throw e_5.error; }
        }
        this.characterHeritageSpecifyOptions = this.characterHeritageSpecifyOptions.slice();
    };
    BookCharactersComponent.prototype.transformCharacterCharacterHeritageSpecify = function (characterCharacterHeritageSpecifies) {
        var _this = this;
        characterCharacterHeritageSpecifies.forEach(function (item) {
            var data = {
                option: item.characterHeritageSpecifyId,
                displayText: item.characterHeritageSpecify.name
            };
            _this.selectedCharacterHeritageSpecifies.push(data);
        });
    };
    BookCharactersComponent.prototype.setSelectedCharacterHeritageSpecify = function (characterHeritageSpecifies) {
        // If the user removed all publishers, mark them all for deletion
        this.selectedCharacterHeritageSpecifies = characterHeritageSpecifies;
        this.selectedCharacterHeritageSpecifies = this.selectedCharacterHeritageSpecifies.slice();
        this.updateCharacterCharacterHeritageSpecifyToSave();
    };
    BookCharactersComponent.prototype.updateCharacterCharacterHeritageSpecifyToSave = function () {
        var _this = this;
        // Transform multi-select data into book publishers
        var updatedCharacterHeritageSpecifies = new Array();
        this.selectedCharacterHeritageSpecifies.forEach(function (item) {
            var characterHeritageSpecify = {
                characterId: _this.model.id,
                characterHeritageSpecifyId: item.option,
                characterHeritageSpecify: null
            };
            updatedCharacterHeritageSpecifies.push(characterHeritageSpecify);
        });
        this.model.charactersCharacterHeritageSpecifies = updatedCharacterHeritageSpecifies;
    };
    /**End of Functions of Heritage & Heritage specifies */
    /** Function of Character Religions */
    BookCharactersComponent.prototype.getAllReligions = function () {
        var _this = this;
        this.bookService.getAllReligions().subscribe(function (religions) {
            if (religions != undefined && religions.length > 0) {
                _this.allReligions = religions;
                _this.assembleReligionOptions(religions);
            }
        });
    };
    BookCharactersComponent.prototype.assembleReligionOptions = function (religions) {
        var e_6, _a;
        try {
            for (var religions_1 = tslib_1.__values(religions), religions_1_1 = religions_1.next(); !religions_1_1.done; religions_1_1 = religions_1.next()) {
                var religion = religions_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: religion.id,
                    displayText: religion.name
                };
                this.religionOptions.push(data);
            }
        }
        catch (e_6_1) { e_6 = { error: e_6_1 }; }
        finally {
            try {
                if (religions_1_1 && !religions_1_1.done && (_a = religions_1.return)) _a.call(religions_1);
            }
            finally { if (e_6) throw e_6.error; }
        }
        this.religionOptions = this.religionOptions.slice();
    };
    BookCharactersComponent.prototype.transformCharacterReligion = function (charactersReligions) {
        var _this = this;
        charactersReligions.forEach(function (item) {
            var data = {
                option: item.religionId,
                displayText: item.religion.name
            };
            _this.selectedReligions.push(data);
        });
    };
    BookCharactersComponent.prototype.setSelectedReligion = function (characterReligions) {
        // If the user removed all publishers, mark them all for deletion
        this.selectedReligions = characterReligions;
        this.selectedReligions = this.selectedReligions.slice();
        this.updateCharacterReligionToSave();
    };
    BookCharactersComponent.prototype.updateCharacterReligionToSave = function () {
        var _this = this;
        // Transform multi-select data into book publishers
        var updatedCharacterReligions = new Array();
        this.selectedReligions.forEach(function (item) {
            var characterReligion = {
                characterId: _this.model.id,
                religionId: item.option,
                religion: null
            };
            updatedCharacterReligions.push(characterReligion);
        });
        this.model.charactersReligions = updatedCharacterReligions;
    };
    /** End OF Function of Character Religions */
    /** Function of Character Disability */
    BookCharactersComponent.prototype.getAllCharacterDisabilities = function () {
        var _this = this;
        this.bookService.getAllDisabilities().subscribe(function (disabilities) {
            if (disabilities != undefined && disabilities.length > 0) {
                _this.allCharacterDisabilities = disabilities;
                _this.assembleDisabilityOptions(disabilities);
            }
        });
    };
    BookCharactersComponent.prototype.assembleDisabilityOptions = function (disabilities) {
        var e_7, _a;
        try {
            for (var disabilities_1 = tslib_1.__values(disabilities), disabilities_1_1 = disabilities_1.next(); !disabilities_1_1.done; disabilities_1_1 = disabilities_1.next()) {
                var disability = disabilities_1_1.value;
                // Convert data to match IMultiSelectOptions
                var data = {
                    option: disability.id,
                    displayText: disability.name
                };
                this.characterDisabilityOptions.push(data);
            }
        }
        catch (e_7_1) { e_7 = { error: e_7_1 }; }
        finally {
            try {
                if (disabilities_1_1 && !disabilities_1_1.done && (_a = disabilities_1.return)) _a.call(disabilities_1);
            }
            finally { if (e_7) throw e_7.error; }
        }
        this.characterDisabilityOptions = this.characterDisabilityOptions.slice();
    };
    BookCharactersComponent.prototype.transformCharacterCharacterDisability = function (characterDisabilities) {
        var _this = this;
        characterDisabilities.forEach(function (item) {
            var data = {
                option: item.disabilityId,
                displayText: item.disability.name
            };
            _this.selectedCharacterDisabilities.push(data);
        });
    };
    BookCharactersComponent.prototype.setSelectedCharacterDisability = function (disabilities) {
        // If the user removed all publishers, mark them all for deletion
        this.selectedCharacterDisabilities = disabilities;
        this.selectedCharacterDisabilities = this.selectedCharacterDisabilities.slice();
        this.updateCharacterCharacterDisabilityToSave();
    };
    BookCharactersComponent.prototype.updateCharacterCharacterDisabilityToSave = function () {
        var _this = this;
        // Transform multi-select data into book publishers
        var updatedCharacterDisabilities = new Array();
        this.selectedCharacterDisabilities.forEach(function (item) {
            var charactersDisability = {
                characterId: _this.model.id,
                disabilityId: item.option,
                disability: null
            };
            updatedCharacterDisabilities.push(charactersDisability);
        });
        this.model.charactersDisabilities = updatedCharacterDisabilities;
    };
    /** End OF Function of Character Religions */
    BookCharactersComponent.prototype.ngOnInit = function () {
        this.getAllCharacterTypes();
        this.transformCharacterCharacterType(this.model.charactersCharacterTypes);
        this.getAllCharacterGenders();
        this.transformCharacterCharacterGender(this.model.charactersCharacterGenders);
        this.getAllCharacterGenderSpecifies();
        this.transformCharacterCharacterGenderSpecify(this.model.charactersCharacterGenderSpecifies);
        this.getAllCharacterHeritages();
        this.transformCharacterCharacterHeritage(this.model.charactersCharacterHeritages);
        this.getAllCharacterHeritageSpecifies();
        this.transformCharacterCharacterHeritageSpecify(this.model.charactersCharacterHeritageSpecifies);
        this.getAllReligions();
        this.transformCharacterReligion(this.model.charactersReligions);
        this.getAllCharacterDisabilities();
        this.transformCharacterCharacterDisability(this.model.charactersDisabilities);
        this.getAllCharacterSexualities();
        this.prefix = (this.model.isSecondaryCharacter ? "secondary" : "primary") + this.index;
    };
    /**
     * Close the expanded "character" panel
     * @param {string} prefix of the clicked expansion panel
     */
    BookCharactersComponent.prototype.openCharacterPanel = function (prefix) {
        var panelTitle = document.getElementById(prefix + 'panelTitle');
        if (panelTitle != undefined) {
            panelTitle.classList.add('opened');
        }
    };
    /**
     * Close the expanded "character" panel
     * @param {string} prefix of the clicked expansion panel
     */
    BookCharactersComponent.prototype.closeCharacterPanel = function (prefix) {
        var panelTitle = document.getElementById(prefix + 'panelTitle');
        if (panelTitle != undefined) {
            panelTitle.classList.remove('opened');
        }
    };
    return BookCharactersComponent;
}());
export { BookCharactersComponent };
